import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '../../../../components/Button';
import socketIOClient from 'socket.io-client';
import Config from '../../../../const/Config';
import { get, post } from '../../../../helpers/ApiHelper';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { getDate, now } from '../../../../helpers/TimeHelper';
import Loader from '../../../../components/Loader';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
	absentOption: {

	},
	button: {
		border: `1px solid ${theme.palette.grey[300]}`,
		borderRadius: 10,
		width: '80%',
	},
	errorBox: {
		color: 'red',
	},
	selected: {
		backgroundColor: '#f8ac59',
		borderColor: '#f8ac59',
		color: 'white',
	},
	studentContainer: {
		alignItems: 'center',
		border: '1px solid #cccccc',
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2),
	},
}));

const CollectHomeworkDialog = forwardRef(({ fetchHomework = () => {}, fetchRecord = () => {} }, ref) => {
	const classes = useStyles();
	const { classId } = useParams();
	const socket = useRef();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const [homeworkId, setHomeworkId] = useState(false);
	const [students, setStudents] = useState([]);
	const [selectedStudents, setSelectedStudents] = useState([]);

	const [status, setStatus] = useState({});

	const onClose = () => setOpen(false);
	useImperativeHandle(ref, () => ({
		open: (homeworkId, outstandingRecords, absentRecords, collected = false) => {
			setHomeworkId(homeworkId);
			setStatus({});
			// if (collected) {
			// 	const status = {};
			// 	students.map(({id}) => {
			// 		if(outstandingRecords.indexOf(id) >= 0) status[id] = 'outstanding';
			// 		else if(absentRecords.indexOf(id) >= 0) status[id] = 'absent';
			// 		else status[id] = 'submit';
			// 	})
			// 	setStatus(status);
			// } else {
			// 	setStatus({});
			// }
			setOpen(true);
		},
	}));

	const fetchInfo = async () => {
		setLoading(true);

		const students = await get('getStudentByClass/' + classId);
		if (students.status !== false && students.result !== undefined) {
			setStudents(students.result);
		}

		setLoading(false);
	};

	useEffect(() => {
		socket.current = socketIOClient(Config.host);
	}, []);

	useEffect(() => {
		fetchInfo();
	}, []);

	const onStatusChange = (id, type, checked) => {
		setStatus(status => ({
			...status,
			[id]: checked? type : null
		}))
	}

	const toggleAll = () => {
		const status = {};
		students.map(({ id }) => status[id] = 'submit');
		setStatus(status);
	};

	const collect = () => {
		const outstandingRecords = [];
		const absentRecords = [];
		students.map(({ id }) => {
			if(status[id] === 'outstanding') outstandingRecords.push(id);
			if(status[id] === 'absent') absentRecords.push(id);
		});

		const data = {
			homeworkId,
			outstanding: outstandingRecords,
			absent: absentRecords,
			updateDate: getDate(now()),
		};

		setLoading(true);
		post('collectHomework', data).then(({ status }) => {
			if (status) {
				socket.current.emit('edit');
				fetchHomework();
				fetchRecord();
				onClose();
				setLoading(false);
			}
		});
	};

	return (
		<React.Fragment>
			<Loader loading={loading} />
			<Dialog fullScreen open={open} onClose={onClose}>
				<AppBar className="dialog-app-bar">
					<Toolbar>
						<IconButton color="inherit" onClick={onClose}>
							<Icon>close</Icon>
						</IconButton>
						<Typography variant="h6" className="dialog-title">
							點收家課
						</Typography>
					</Toolbar>
				</AppBar>
				<Box mt={3} mx={3}>
					<Container>
						<Box mb={2}>
							<Typography variant="h6">請選擇已提交同學：</Typography>
						</Box>
						<Box mb={2}>
							<Button variant="contained" color="secondary" onClick={toggleAll}>
								全選提交
							</Button>
						</Box>
						<Box mb={5}>
						{students.length > 0 ? (
							<Grid container spacing={3}>
								{students.map(({ id, class_number, chi_name}, index) => {
									const studentStatus = status[id];
									return (
										<Grid key={index} item xs={12} sm={6} md={4}>
											<Box className={classes.studentContainer}>
												<Typography variant="subtitle1">{chi_name}</Typography>
												<Box>
													<FormControlLabel value="submit" control={
														<Radio
															color="primary"
															checked={studentStatus === 'submit'}
															onChange={(event) => onStatusChange(id, 'submit', event.target.checked)}
														/>
													} label="已提交" />
													<FormControlLabel value="outstanding" control={
														<Radio
															color="secondary"
															checked={studentStatus === 'outstanding'}
															onChange={(event) => onStatusChange(id, 'outstanding', event.target.checked)}
														/>
													} label="欠交" />
													<FormControlLabel value="absent" control={
														<Radio
															className="success"
															checked={studentStatus === 'absent'}
														   	onChange={(event) => onStatusChange(id, 'absent', event.target.checked)}
														/>
													} label="缺席" />
												</Box>
											</Box>
										</Grid>
									)
								})}
							</Grid>
						) : (
							<Typography>沒有學生資料</Typography>
						)}
						</Box>
						<Box alignItems="center" display="flex" justifyContent="center" mb={5}>
							<Box mr={3}>
								<Fab variant="extended" size="medium" onClick={onClose}>
									<Icon>close</Icon>取消
								</Fab>
							</Box>
							<Box>
								<Fab variant="extended" color="primary" size="medium" onClick={collect}>
									<Icon>save</Icon>儲存
								</Fab>
							</Box>
						</Box>
					</Container>
				</Box>
			</Dialog>
		</React.Fragment>
	);
});

export default CollectHomeworkDialog;
