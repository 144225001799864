import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '../../../../components/Button';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { post } from '../../../../helpers/ApiHelper';
import { getDate, now } from '../../../../helpers/TimeHelper';
import socketIOClient from 'socket.io-client';
import Config from '../../../../const/Config';
import Loader from '../../../../components/Loader';


const OutstandingRow = ({ homeworkId, outstandings = [], fetchRecord = () => {} }) => {
	const socket = useRef();

	const [loading, setLoading] = useState(false);
	const [selectedIds, setSelectedIds] = useState([]);

	useEffect(() => {
		socket.current = socketIOClient(Config.host);
	}, []);

	const handleSelect = (id) => {
		const index = selectedIds.indexOf(id);
		if (index !== -1) {
			setSelectedIds((selectedIds) => [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1)]);
		} else {
			setSelectedIds((selectedIds) => [...selectedIds, id]);
		}
	};

	const handleResubmit = () => {
		const data = {
			homeworkId,
			submitDate: getDate(now()),
			outstandingIds: selectedIds,
		};

		setLoading(true);
		post('resubmitHomework', data).then(({ status }) => {
			if (status) {
				socket.current.emit('edit');
				setSelectedIds([]);
				fetchRecord();
				setLoading(false);
			}
		});
	};

	return (
		<React.Fragment>
			<Loader loading={loading} />
			<Box mt={1} display="flex" flexWrap="wrap" alignItems="center">
				{outstandings.map(({ id, class_number, student_chi_name }, index) => (
					<Box key={index} mr={1} mb={1}>
						<Chip
							clickable
							color="secondary"
							variant="outlined"
							label={student_chi_name}
							onClick={() => handleSelect(id)}
							onDelete={() => handleSelect(id)}
							deleteIcon={selectedIds.indexOf(id) >= 0 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
						/>
					</Box>
				))}
			</Box>
			{selectedIds.length > 0 && (
				<Button variant="contained" color="primary" startIcon={<DoneAllIcon />} onClick={handleResubmit}>
					確認補交
				</Button>
			)}
		</React.Fragment>
	);
};

export default OutstandingRow;
