import React, {  useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams } from 'react-router-dom';
import { get, post } from '../../helpers/ApiHelper';
import { getCurrentYearId } from '../../helpers/TimeHelper';
import Loader from '../../components/Loader';
import { Button, FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import Config from '../../const/Config';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch } from 'react-redux';
import { setClassId, setIsAuth, setRole } from '../../redux/user_slice';

const styles = makeStyles((theme) => ({
	root: {},
	gridContainer: {
		width: '90%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	select: {
		backgroundColor: 'white',
	},
}));

const BlackboardLogin = () => {
	const classes = styles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { type } = useParams();

	const [isLoading, setIsLoading] = useState(true);
	const [currentYearId, setCurrentYearId] = useState(null);
	const [classOptions, setClassOptions] = useState([]);
	const [selectedClassId, setSelectedClassId] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchYear = async () => {
			const { status, result } = await get('getYear');
			if (status !== false && result !== undefined) {
				const currentYearId = getCurrentYearId(result);
				setCurrentYearId(currentYearId);
			}
		};
		fetchYear();
	}, []);

	useEffect(() => {
		const fetchClass = async () => {
			const { status, result } = await get('getClass/' + currentYearId);
			if (status !== false && result !== undefined) {
				setClassOptions(result);
			}
			setIsLoading(false);
		};
		if (currentYearId) fetchClass();
	}, [currentYearId]);

	const login = async () => {
		if (!selectedClassId) return setError('請選擇班別');

		setIsLoading(true);
		const data = { type, classId: selectedClassId };
		await post('login', data);
		dispatch(setIsAuth(true));
		dispatch(setRole(type));
		dispatch(setClassId(selectedClassId));
		history.push(`/${type}/blackboard`);
	};

	return (
		<React.Fragment>
			<Loader isLoading={isLoading} />

			<Box className="blackboard-background white-text view-height-100" display="flex" alignItems="center" justifyContent="center">
				<Grid container direction="column" spacing={2} className={classes.gridContainer}>
					<Grid item>
						<Typography variant="h5" align="center">
							{Config.schoolName}學校家課板
						</Typography>
					</Grid>
					<Grid item>
						<FormControl variant="outlined" fullWidth className={classes.select}>
							<Select value={selectedClassId || ''} onChange={(event) => setSelectedClassId(event.target.value)} displayEmpty>
								<MenuItem value="">選擇班別</MenuItem>
								{classOptions.map(({ class_id, class_name }) => (
									<MenuItem key={class_id} value={class_id}>
										{class_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item>
						<Button variant="contained" color="secondary" fullWidth startIcon={<ExitToAppIcon />} onClick={login}>
							進入
						</Button>
					</Grid>
					<Grid item>
						<Typography align="center" variant="h6">
							{error}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</React.Fragment>
	);
};

export default BlackboardLogin;
