import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedYear, setSelectedYear } from '../../../redux/user_slice';
import Loader from '../../../components/Loader';
import Nav from '../../../components/Nav';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import Container from '@material-ui/core/Container';
import SectionPaper from '../../../components/SectionPaper';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { get, post } from '../../../helpers/ApiHelper';
import { getCurrentYearId, getDate, now } from '../../../helpers/TimeHelper';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { OutlinedInput } from '@material-ui/core';
import Button from '../../../components/Button';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { useHistory, useLocation } from 'react-router-dom';

const styles = makeStyles((theme) => ({
	root: {},
	input: {
		width: 200,
	},
	statistic: {
		fontWeight: 600,
	},
	table: {
		minWidth: '100%',
	},
	tableHeader: {
		fontWeight: 600,
	},
	tableRow: {
		cursor: 'pointer',
	}
}));

const AnalysisTeacher = (props) => {
	const classes = styles();
	const history = useHistory();
	const { search } = useLocation();

	const dispatch = useDispatch();
	const selectedYear = useSelector(selectSelectedYear)?.toString() || '';

	const [analysis, setAnalysis] = useState([]);
	const [classOptions, setClassOptions] = useState([]);
	const [details, setDetails] = useState({
		startDate: now(),
		endDate: now(),
	});
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(0);
	const [paramDetails, setParamDetails] = useState({});
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [show, setShow] = useState(false);
	const [years, setYears] = useState([]);

	useMemo(() => {
		const query = new URLSearchParams(search);
		const obj = {
			startDate: query.get('startDate') || now(),
			endDate: query.get('endDate') || now(),
			belongs: query.get('belongs'),
			classId: query.get('classId'),
			subjectId: query.get('subjectId'),
			studentId: query.get('studentId'),
			studentClassId: query.get('studentClassId'),
			status: query.get('status'),
		};
		setParamDetails(obj);
		setDetails(obj);
	}, [search]);

	useEffect(() => {
		const fetch = async () => {
			const years = await get('getYear');
			if (years.status !== false && years.result !== undefined) {
				setYears(years.result);
				if (!selectedYear) dispatch(setSelectedYear(getCurrentYearId(years.result)));
			}
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			const classOptions = await get(`getPersonalTeacherBelongsByYear/${selectedYear}`);
			if (classOptions.status !== false && classOptions.result !== undefined) {
				setClassOptions(classOptions.result);
			}

			setLoading(false);
		};
		if (selectedYear) fetch();
	}, [selectedYear]);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const { startDate, endDate, belongs, subjectId, studentId, studentClassId, status } = paramDetails;
			const selectedClass = classOptions.find((classOption) => classOption.belongs === belongs);

			const data = {
				year_id: selectedYear,
				start_date: getDate(startDate),
				end_date: getDate(endDate),
				belongs:  belongs.length > 2? null : belongs,
				class_id: belongs.length > 2? selectedClass.class_id : null,
				subject_id: subjectId,
				student_id: studentId,
				student_class_id: studentClassId,
				status,
			};

			let students = [];
			if(belongs.length > 2) {
				students = await get(`getStudentByClass/${selectedClass.class_id}`);
			}else{
				students = await get(`getStudentBelongsByYear/${selectedYear}/${belongs}`) ;
			}
			const studentStatus = students.status !== false && students.result !== undefined;

			const {outstanding, isSubmitted} = await post(`getOutstandingAnalysisTotalByStudent`, data);
			const analysis = studentStatus? students.result.map((student) => {
				let outstandingTotal = 0;
				let isSubmittedTotal = 0;

				outstanding.map(({student_id, count}) => {
					if(student_id === student.student_id) outstandingTotal += count;
				});

				isSubmitted.map(({student_id, count}) => {
					if(student_id === student.student_id) isSubmittedTotal += count;
				});

				return {
					...student,
					outstandingTotal,
					isSubmittedTotal
				}
			}): [];

			setAnalysis(analysis);
			setShow(true);
			setLoading(false);
		}
		if(selectedYear && classOptions.length > 0 && paramDetails.belongs) fetch();
	}, [selectedYear, classOptions, paramDetails])

	const changePage = (event, newPage) => {
		setPage(newPage);
	};

	const changeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const updateYear = (value) => {
		dispatch(setSelectedYear(value));
	};

	const updateDetails = (key, value) => {
		setLoading(true);
		setDetails((details) => ({
			...details,
			[key]: value,
		}));
		setLoading(false);
	};

	const getResult = async () => {
		let query = '';
		Object.keys(details).map((key) => {
			if(details[key]) {
				let value = details[key];
				if (key === 'startDate' || key === 'endDate') value = getDate(value);
				query += `&${key}=${value}`;
			}
		})
		history.push(`/analysis-teacher${query? `?${query.slice(1)}` : ''}`);
	};

	const PagingComponent = () => (
		<TablePagination
			labelRowsPerPage="每頁顯示"
			rowsPerPageOptions={[10, 20, 30]}
			component="div"
			count={analysis.length}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={changePage}
			onRowsPerPageChange={changeRowsPerPage}
		/>
	);

	const goToDetail = (studentId) => {
		history.push(`/analysis-teacher/detail/${studentId}`);
	}

	const tableHeaders = [
		{ key: 'student_key', label: '學生編號' },
		{ key: 'belongs', label: '所屬班別' },
		{ key: 'student_name', label: '名稱' },
		{ key: 'total', label: '總欠交記錄' },
		{ key: 'outstandingTotal', label: '仍欠交' },
		{ key: 'isSubmittedTotal', label: '已補交' },
	];

	return (
		<Fragment>
			<Loader loading={loading} />
			<div className="root">
				<Nav title="數據分析" />
				<Container className="root-content">
					<ToolbarBuffer />
					<SectionPaper>
						<Box display="flex" alignItems="center">
							<FormControl variant="outlined" className={classes.input}>
								<InputLabel id="year">年度</InputLabel>
								<Select labelId="year" value={selectedYear} labelWidth={35} onChange={(event) => updateYear(event.target.value)}>
									{years.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Box m={1} />
							<KeyboardDatePicker
								className={classes.input}
								autoOk
								inputVariant="outlined"
								label="開始日期"
								format="YYYY-MM-DD"
								value={details['startDate']}
								onChange={(selectedDate) => updateDetails('startDate', selectedDate.format())}
							/>
							<Box m={1} />
							<KeyboardDatePicker
								className={classes.input}
								autoOk
								inputVariant="outlined"
								label="結束日期"
								format="YYYY-MM-DD"
								value={details['endDate']}
								onChange={(selectedDate) => updateDetails('endDate', selectedDate.format())}
							/>
							<Box m={1} />
							<FormControl variant="outlined" className={classes.input}>
								<InputLabel id="class" shrink>班別</InputLabel>
								<Select
									labelId="class"
									displayEmpty
									value={details['belongs'] || ''}
									labelWidth={35}
									input={<OutlinedInput notched label="" />}
									onChange={(event) => updateDetails('belongs', event.target.value)}
								>
									<MenuItem value="">選擇班別</MenuItem>
									{classOptions.filter(({belongs}) => !!belongs).map(({ belongs }) => (
										<MenuItem key={belongs} value={belongs}>
											{belongs}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Box m={1} />
							<Button
								variant="contained"
								onClick={getResult}
							>
								搜尋
							</Button>
						</Box>
					</SectionPaper>
					{show && (
						<SectionPaper>
							<PagingComponent />
							<TableContainer>
								<Table className={classes.table}>
									<TableHead>
										<TableRow>
											{tableHeaders.map(({ key, label }) => (
												<TableCell key={key}>
													<Typography className={classes.tableHeader}>{label}</Typography>
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{analysis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
											<TableRow key={index} className={classes.tableRow} hover onClick={() => goToDetail(row.student_id)}>
												{tableHeaders.map(({ key, type }) => {
													let value = row[key];
													if (key === 'student_name') value = row['chi_name'] || row['eng_name'];
													if (key === 'total') value = row.outstandingTotal + row.isSubmittedTotal;
													return (
														<TableCell key={key}>
															<Typography>{value}</Typography>
														</TableCell>
													);
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							<PagingComponent />
						</SectionPaper>
					)}
				</Container>
			</div>
		</Fragment>
	)
};

export default AnalysisTeacher;
