import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Loader from '../../../components/Loader';
import Nav from '../../../components/Nav';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { get, post } from '../../../helpers/ApiHelper';
import { selectSelectedYear, setSelectedYear } from '../../../redux/user_slice';
import { getCurrentYearId, getDate, now } from '../../../helpers/TimeHelper';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { OutlinedInput, TextField } from '@material-ui/core';
import Button from '../../../components/Button';
import SectionPaper from '../../../components/SectionPaper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { excelTemplate } from '../../../helpers/ExcelHelper';
import moment from 'moment';

const styles = makeStyles((theme) => ({
	root: {},
	input: {
		width: 200,
	},
	statistic: {
		fontWeight: 600,
	},
	table: {
		minWidth: '100%',
	},
	tableHeader: {
		fontWeight: 600,
	},
}));

const AnalysisAdmin = (props) => {
	const classes = styles();

	const dispatch = useDispatch();
	const selectedYear = useSelector(selectSelectedYear)?.toString() || '';
	const currentDate = moment();

	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [exportId, setExportId] = useState('');
	const [years, setYears] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [classOptions, setClassOptions] = useState([]);
	const [students, setStudents] = useState([]);
	const [filteredStudents, setFilteredStudents] = useState([]);
	const [details, setDetails] = useState({
		startDate: now(),
		endDate: now(),
	});
	const [analysisTotal, setAnalysisTotal] = useState({});
	const [analysis, setAnalysis] = useState([]);
	const [bufferEditDay, setBufferEditDay] = useState(0)

	useEffect(() => {
		const fetch = async () => {
			const settingInfo = await get('getOneSetting/bufferEditDay');
			const settingInfoStatus = settingInfo.status !== false && settingInfo.result !== undefined;
			setBufferEditDay(settingInfoStatus? settingInfo.result[0].integer : 0);

			const years = await get('getYear');
			if (years.status !== false && years.result !== undefined) {
				setYears(years.result);
				if (!selectedYear) dispatch(setSelectedYear(getCurrentYearId(years.result)));
			}

			const subjects = await get('getSubject');
			if (subjects.status !== false && subjects.result !== undefined) {
				setSubjects(subjects.result);
			}
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			const classOptions = await get(`getPersonalTeacherBelongsByYear/${selectedYear}`);

			if (classOptions.status !== false && classOptions.result !== undefined) {
				setClassOptions(classOptions.result);
			}

			const students = await get(`getStudentBelongsByYear/${selectedYear}`);
			if (students.status !== false && students.result !== undefined) {
				const { result } = students;
				setStudents(result);
				setFilteredStudents(result);
			}

			setLoading(false);
		};
		if (selectedYear) fetch();
	}, [selectedYear]);

	useEffect(() => {
		if (show) search();
	}, [details.status]);

	const updateYear = (value) => {
		dispatch(setSelectedYear(value));
	};

	const updateDetails = (key, value) => {
		setLoading(true);
		const studentClassId = key === 'classId' ? null : details.studentClassId;
		setDetails((details) => ({
			...details,
			studentClassId,
			[key]: value,
		}));
		if (key === 'belongs') {
			setFilteredStudents(students.filter(({ belongs }) => belongs === value));
		}
		setLoading(false);
	};

	const search = async () => {
		const { startDate, endDate, belongs, classId, subjectId, studentId, studentClassId, status } = details;
		const data = {
			year_id: selectedYear,
			start_date: getDate(startDate),
			end_date: getDate(endDate),
			belongs,
			class_id: classId,
			subject_id: subjectId,
			student_id: studentId,
			student_class_id: studentClassId,
			status,
		};
		// const analysisTotal = await post(`getOutstandingAnalysisTotal`, data);
		// setAnalysisTotal(analysisTotal);

		const analysis = await post(`getOutstandingAnalysis`, data);
		const { status: analysisStatus, result: analysisResult } = analysis;

		if(analysisStatus) {
			const records = analysisResult.filter((record) => {
				const { homework_update_date } = record;
				const differenceDay = currentDate.diff(homework_update_date, 'days');
				return differenceDay > bufferEditDay;
			});

			const total = records.length;
			const outstandingTotal = records.filter(({is_submitted}) => !is_submitted).length;
			const isSubmittedTotal = total - outstandingTotal;
			setAnalysis(records);
			setAnalysisTotal({ total, outstandingTotal, isSubmittedTotal})
		}else{
			setAnalysis([]);
			setAnalysisTotal({ total: 0, outstandingTotal: 0, isSubmittedTotal: 0,})
		}
	};

	const getNames = () => {
		const { belongs, subjectId, classId, studentClassId, status } = details;
		const year = years.find(({ id }) => id === parseInt(selectedYear));
		const subject = subjects.find(({ id }) => id === parseInt(subjectId));
		const student = filteredStudents.find(({ id }) => id === parseInt(studentClassId));

		return {
			yearName: year?.name,
			subjectName: subject?.name || null,
			className: belongs || null,
			studentName: student?.chi_name || student?.eng_name || null,
			statusName: status === 'outstandingTotal' ? '仍欠交記錄' : status === 'isSubmittedTotal' ? '已補交' : '總欠交記錄',
		};
	};

	const download = () => {
		const { yearName, subjectName, className, studentName, statusName } = getNames();
		const title = `${yearName}${!!subjectName ? `-${subjectName}` : ''}${!!className ? `-${className}` : ''}${
			!!studentName ? `-${studentName}` : ''
		} ${statusName}`;
		const excelHeaders = tableHeaders.map(({ label }) => label);

		let data = [excelHeaders];
		analysis.map((row) => {
			if (!!exportId && row.id < exportId) return;
			let rowData = [];
			tableHeaders.map(({ key, type = null }) => {
				let value = row[key];
				if (key === 'student_name') value = row['student_chi_name'] || row['student_eng_name'];
				if (type === 'date') value = !!value ? getDate(value) : '';
				rowData.push(value);
			});
			data.push(rowData);
		});
		excelTemplate(title, data);
	};

	const changePage = (event, newPage) => {
		setPage(newPage);
	};

	const changeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const dateItems = [
		{
			key: 'startDate',
			label: '開始日期',
		},
		{
			key: 'endDate',
			label: '結束日期',
		},
	];

	const filterItems = [
		{
			key: 'subjectId',
			label: '科目',
			options: subjects.map(({ id, name }) => (
				<MenuItem key={id} value={id}>
					{name}
				</MenuItem>
			)),
		},
		{
			key: 'belongs',
			label: '班別',
			options: classOptions.filter(({belongs}) => !!belongs).map(({ belongs }) => (
				<MenuItem key={belongs} value={belongs}>
					{belongs}
				</MenuItem>
			)),
		},
		{
			key: 'studentId',
			label: '學生',
			options: filteredStudents.map(({ student_id, chi_name, eng_name }) => (
				<MenuItem key={student_id} value={student_id}>
					{`${chi_name || eng_name}`}
				</MenuItem>
			)),
		},
	];

	const statusOptions = [
		{ key: 'outstanding', label: '仍欠交記錄' },
		{ key: 'isSubmitted', label: '已補交記錄' },
	];

	const statisticItems = [
		{ key: 'total', label: '總欠交記錄' },
		{ key: 'outstandingTotal', label: '仍欠交', color: 'secondary' },
		{ key: 'isSubmittedTotal', label: '已補交', color: 'primary' },
	];

	const tableHeaders = [
		{ key: 'id', label: '編號' },
		{ key: 'student_key', label: '學生編號' },
		{ key: 'belongs', label: '所屬班別' },
		{ key: 'student_name', label: '名稱' },
		{ key: 'subject_name', label: '科目' },
		{ key: 'description', label: '內容' },
		{ key: 'homework_create_date', label: '派發日期', type: 'date' },
		{ key: 'homework_submit_date', label: '遞交日期', type: 'date' },
		{ key: 'outstanding_submit_date', label: '補交日期', type: 'date' },
	];

	const PagingComponent = () => (
		<TablePagination
			labelRowsPerPage="每頁顯示"
			rowsPerPageOptions={[10, 20, 30]}
			component="div"
			count={analysis.length}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={changePage}
			onRowsPerPageChange={changeRowsPerPage}
		/>
	);

	return (
		<Fragment>
			<Loader loading={loading} />

			<div className="root">
				<Nav title="數據分析" />

				<Container className="root-content">
					<ToolbarBuffer />

					<SectionPaper>
						<Box mb={2} display="flex" alignItems="center">
							<FormControl variant="outlined" className={classes.input}>
								<InputLabel id="year">年度</InputLabel>
								<Select labelId="year" value={selectedYear} labelWidth={35} onChange={(event) => updateYear(event.target.value)}>
									{years.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{dateItems.map(({ key, label }) => (
								<React.Fragment key={key}>
									<Box m={1} />
									<KeyboardDatePicker
										className={classes.input}
										autoOk
										inputVariant="outlined"
										label={label}
										format="YYYY-MM-DD"
										value={details[key]}
										onChange={(selectedDate) => updateDetails(key, selectedDate.format())}
									/>
								</React.Fragment>
							))}
						</Box>
						<Box display="flex" alignItems="center">
							{filterItems.map(({ key, label, options }) => {
								return (
									<React.Fragment key={key}>
										<FormControl variant="outlined" className={classes.input}>
											<InputLabel id="class" shrink>
												{label}
											</InputLabel>
											<Select
												labelId="class"
												displayEmpty
												value={details[key] || ''}
												labelWidth={35}
												input={<OutlinedInput notched label={label} />}
												onChange={(event) => updateDetails(key, event.target.value)}
											>
												<MenuItem value="">{`全部${label}`}</MenuItem>
												{options}
											</Select>
										</FormControl>
										<Box m={1} />
									</React.Fragment>
								);
							})}
							<Button
								variant="contained"
								onClick={() => {
									setShow(true);
									search();
								}}
							>
								搜尋
							</Button>
						</Box>
					</SectionPaper>
					{show && (
						<React.Fragment>
							<SectionPaper>
								<Grid container alignItems="center">
									{statisticItems.map(({ key, label, color = 'inherit' }) => (
										<Grid key={key} item xs={12} md>
											<Box display="flex" alignItems="center">
												<Typography variant="subtitle1" className={classes.statistic}>
													{`${label}:`}
												</Typography>
												<Box mx={1} />
												<Typography variant="subtitle1" color={color} className={classes.statistic}>
													{analysisTotal[key]}
												</Typography>
											</Box>
										</Grid>
									))}
								</Grid>
							</SectionPaper>
							<SectionPaper>
								<Grid container alignItems="center" justifyContent="space-between">
									<Grid item>
										<FormControl variant="outlined" className={classes.input}>
											<InputLabel id="status" shrink>
												顯示
											</InputLabel>
											<Select
												labelId="status"
												displayEmpty
												value={details.status || ''}
												labelWidth={35}
												input={<OutlinedInput notched label="顯示" />}
												onChange={(event) => updateDetails('status', event.target.value)}
											>
												<MenuItem value="">全部記錄</MenuItem>
												{statusOptions.map(({ key, label }) => (
													<MenuItem key={key} value={key}>
														{label}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item>
										<Grid container alignItems="center" spacing={2}>
											<Grid item>
												<TextField
													label="從指定編號開始匯出"
													variant="outlined"
													type="number"
													value={exportId || ''}
													onChange={(event) => setExportId(event.target.value)}
												/>
											</Grid>
											<Grid item>
												<Button variant="contained" color="primary" onClick={download}>
													匯出記錄
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Box my={2} />
								<PagingComponent />
								<TableContainer>
									<Table className={classes.table}>
										<TableHead>
											<TableRow>
												{tableHeaders.map(({ key, label }) => (
													<TableCell key={key}>
														<Typography className={classes.tableHeader}>{label}</Typography>
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{analysis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
												<TableRow key={index}>
													{tableHeaders.map(({ key, type = null }) => {
														let value = row[key];
														if (key === 'student_name') value = row['student_chi_name'] || row['student_eng_name'];
														if (type === 'date') value = !!value ? getDate(value) : '-';
														return (
															<TableCell key={key}>
																<Typography>{value}</Typography>
															</TableCell>
														);
													})}
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
								<PagingComponent />
							</SectionPaper>
						</React.Fragment>
					)}
				</Container>
			</div>
		</Fragment>
	);
};

export default AnalysisAdmin;
