import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

const styles = makeStyles((theme) => ({
	root: {
		boxShadow: theme.boxShadow,
	},
}));

const SectionPaper = ({ children }) => {
	const classes = styles();
	return (
		<Box className={classes.root} component={Paper} p={3} my={3}>
			{children}
		</Box>
	);
};

export default SectionPaper;
