import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user_slice';

export default configureStore({
	reducer: {
		user: userReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
