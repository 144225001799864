import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { selectClassId, selectRole, setClassId, setIsAuth, setRole } from '../../redux/user_slice';
import Loader from '../../components/Loader';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Config from '../../const/Config';
import { IconButton, Tab, Tabs } from '@material-ui/core';
import { get, post } from '../../helpers/ApiHelper';
import { getDate, now, time, tomorrow, yesterday } from '../../helpers/TimeHelper';
import HomeIcon from '@material-ui/icons/Home';
import { DatePicker } from '@material-ui/pickers';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import BlackboardHomeworkList from './_partials/BlackboardHomeworkList';
import BlackboardCollectList from './_partials/BlackboardCollectList';
import { useHistory, useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client';

const styles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#116D4D',
		color: 'white',
		minHeight: '100vh',
	},
	title: {
		fontWeight: 'bold',
	},
	pointer: {
		cursor: 'pointer',
	},
	arrowIcon: {
		fontSize: 30,
		color: 'white',
	},
	tabName: {
		fontSize: 35,
		fontWeight: 'bold',
	},
}));

const Blackboard = (props) => {
	const classes = styles();
	const history = useHistory();
	const dispatch = useDispatch();
	const socket = useRef();

	const { type } = useParams();
	const role = useSelector(selectRole);
	const classId = useSelector(selectClassId);
	const isClass = role === 'class' && type === 'class';

	const [loading, setLoading] = useState(true);
	const [classInfo, setClassInfo] = useState({});

	const [openCalendar, setOpenCalendar] = useState(false);
	const [tabIndex, setTabIndex] = useState('create');
	const [selectedDate, setSelectedDate] = useState(now());
	const [homeworks, setHomeworks] = useState([]);
	const [outstandings, setOutstandings] = useState([]);

	const fetch = async () => {
		setLoading(true);

		const classInfo = await get(`getOneClass/${classId}`);
		const classInfoStatus = classInfo.status !== false && classInfo.result !== undefined;
		setClassInfo(classInfoStatus ? classInfo.result[0] : {});

		setLoading(false);
	};

	const fetchHomework = async () => {
		setLoading(true);

		const classIds = [classId];
		const homeworks = await get(`getClassHomework/${classIds}/${tabIndex}/${getDate(selectedDate)}`);
		const homeworksStatus = homeworks.status !== false && homeworks.result !== undefined;
		setHomeworks(homeworksStatus ? homeworks.result : []);

		setLoading(false);
	};

	const fetchRecord = async () => {
		setLoading(true);

		const homeworkIds = homeworks.map(({ id }) => id);

		const outstandings = await get(`getOutstandingByHomework/${homeworkIds}`);
		const outstandingsStatus = outstandings.status !== false && outstandings.result !== undefined;
		setOutstandings(outstandingsStatus ? outstandings.result : []);

		setLoading(false);
	};

	useEffect(() => {
		if (!!classId) fetch();
	}, []);

	useEffect(() => {
		fetchHomework();
	}, [tabIndex, selectedDate]);

	useEffect(() => {
		if (homeworks.length > 0) fetchRecord();
	}, [homeworks]);

	useEffect(() => {
		setInterval(() => {
			if (time() === '0:00:00') {
				this.setState({ selectedDate: now() });
			}
		}, 1000);
	}, []);

	useEffect(() => {
		socket.current = socketIOClient(Config.host);
		socket.current.on('edit', () => fetchHomework());

		return () => {
			if (!!socket.current) socket.current.removeAllListeners();
		};
	}, []);

	const logout = async () => {
		setLoading(true);
		await post('logout');
		dispatch(setIsAuth(false));
		dispatch(setRole(null));
		dispatch(setClassId(null));
		history.push(`/${type}/blackboard`);
	};

	return (
		<React.Fragment>
			<Loader loading={loading} />

			<Box px={1} display="flex" flexDirection="column" height="100%" className={classes.root}>
				<Box p={1} display="flex" alignItems="center">
					<HomeIcon onClick={logout} className={classes.pointer} />
					<Box mx={2}>
						<Typography className={classes.title}>></Typography>
					</Box>
					<Typography className={classes.title}>{classInfo.class_name}班家課表</Typography>
				</Box>

				<Divider />

				<Box p={1} display="flex" alignItems="center" justifyContent="center" className={classes.pointer}>
					<IconButton onClick={() => setSelectedDate(yesterday(selectedDate))}>
						<ArrowBackIosIcon className={classes.arrowIcon} />
					</IconButton>
					<Box flexGrow={1} onClick={() => setOpenCalendar(true)}>
						<Typography variant="h4" align="center">
							{selectedDate.locale('zh-hk').format('YYYY年MMMDo dddd')}
						</Typography>
						<Typography variant="h4" align="center">
							{selectedDate.locale('en-es').format('Do, MMMM YYYY')}
						</Typography>
					</Box>
					<IconButton onClick={() => setSelectedDate(tomorrow(selectedDate))}>
						<ArrowForwardIosIcon className={classes.arrowIcon} />
					</IconButton>
				</Box>

				<DatePicker
					autoOk
					className="hidden"
					open={openCalendar}
					onClose={() => setOpenCalendar(false)}
					format="YYYY-MM-DD"
					value={selectedDate}
					onChange={(selectedDate) => setSelectedDate(selectedDate)}
				/>

				<Box px={1} textAlign="center" justifyContent="center">
					<Tabs value={tabIndex} variant="fullWidth" indicatorColor="secondary" centered onChange={(event, value) => setTabIndex(value)}>
						<Tab value="create" label="今日家課" className={classes.tabName} />
						{isClass && <Tab value="submit" label="點收" className={classes.tabName} />}
					</Tabs>
				</Box>

				<Box p={1} flexGrow={1}>
					{tabIndex === 'create' && <BlackboardHomeworkList homeworks={homeworks} />}
					{tabIndex === 'submit' && <BlackboardCollectList homeworks={homeworks} outstandings={outstandings} />}
				</Box>
			</Box>
		</React.Fragment>
	);
};

export default Blackboard;
