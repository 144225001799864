import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { get, post } from '../helpers/ApiHelper';
import Loader from './Loader';
import Config from '../const/Config';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChiName,
  selectEngName,
  selectIsAdmin,
  selectRole,
  selectClassId,
  setChiName,
  setEngName,
  setIsAdmin,
  setIsAuth,
  setRole,
  selectSelectedYear,
} from '../redux/user_slice';

const Nav = ({ home = false, title }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);
  const userRole = useSelector(selectRole);
  const chiName = useSelector(selectChiName);
  const engName = useSelector(selectEngName);
  const classId = useSelector(selectClassId);
  const selectedYear = useSelector(selectSelectedYear)?.toString() || '';

  const [openMenu, setOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [outstandingCount, setOutstandingCount] = useState(0);
  const [resubmitCount, setResubmitCount] = useState(0);

  const fetchStudent = async () => {
    setIsLoading(true);

    const { status, result } = await get(`getOneStudentByYear/${selectedYear}`);
    if (status !== false && result.length > 0) {
      setStudents(result);
    } else {
      setStudents([]);
    }

    setIsLoading(false);
  };

  const fetchOutstanding = async () => {
    setIsLoading(true);

    const classIds = !!classId ? [classId] : students.map(({ class_id }) => class_id);

    if (classIds.length > 0) {
      const outstandings = await get(`getPersonalOutstandingByClass/${classIds}`);
      setOutstandingCount(outstandings.result.length);

      const resubmits = await get(`getPersonalResubmitByClass/${classIds}`);
      setResubmitCount(resubmits.result.length);
    } else {
      setOutstandingCount(0);
      setResubmitCount(0);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedYear) fetchStudent();
  }, [selectedYear]);

  useEffect(() => {
    fetchOutstanding();
  }, [classId, students]);

  const menu = [
    {
      key: 'homeworkList',
      text: '功課列表',
      link: '/student-dashboard',
      icon: 'menu_book',
      roles: ['student', 'parentLogin'],
    },
    {
      key: 'outstandingList',
      text: '仍欠交功課',
      link: '/student-record/outstanding',
      icon: 'cancel_presentation',
      roles: ['student', 'parentLogin'],
      value: outstandingCount,
    },
    {
      key: 'resubmitList',
      text: '已補交功課',
      link: '/student-record/resubmit',
      icon: 'done_all',
      roles: ['student', 'parentLogin'],
      value: resubmitCount,
    },
    {
      key: 'courseList',
      text: '課程列表',
      link: '/course-list',
      icon: 'menu_book',
      roles: ['teacher'],
    },
  ];

  const adminMenu = [
    {
      key: 'studentAnalysis',
      text: '數據分析',
      link: '/analysis-admin',
      icon: 'bar_chart',
      display: isAdmin,
    },
    {
      key: 'studentRecord',
      text: '數據分析',
      link: '/analysis-teacher',
      icon: 'bar_chart',
      display: !isAdmin && userRole === 'teacher',
    },
    {
      key: 'yearSetting',
      text: '年度設定',
      link: '/year-setting',
      icon: 'today',
      display: isAdmin,
    },
    {
      key: 'studentSetting',
      text: '學生設定',
      link: '/student-setting',
      icon: 'people_alt',
      display: isAdmin,
    },
    {
      key: 'teacherSetting',
      text: '老師設定',
      link: '/teacher-setting',
      icon: 'person_pin',
      display: isAdmin,
    },
    {
      key: 'teacherSubjectSetting',
      text: '老師科目設定',
      link: '/teacher-subject-setting',
      icon: 'how_to_reg',
      display: isAdmin,
    },
    {
      key: 'subjectSetting',
      text: '科目設定',
      link: '/subject-setting',
      icon: 'library_books',
      display: isAdmin,
    },
    {
      key: 'homeworkTypeSetting',
      text: '家課設定',
      link: '/homework-type-setting',
      icon: 'list_alt',
      display: isAdmin,
    },
    {
      key: 'platformSetting',
      text: '平台設定',
      link: '/platform-setting',
      icon: 'settings',
      display: isAdmin,
    },
  ];

  const toggleMenu = () => {
    setOpenMenu((prevState) => !prevState);
  };

  const onLogout = async () => {
    setIsLoading(true);
    if (['teacher', 'student', 'parentLogin'].includes(userRole)) await post('user_logout');
    dispatch(setIsAuth(false));
    dispatch(setIsAdmin(false));
    dispatch(setRole(null));
    dispatch(setChiName(null));
    dispatch(setEngName(null));
    history.push('/');
  };

  const displayName = `${chiName || engName || ''}${userRole === 'parentLogin' ? ' (家長)' : ''}`;

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <AppBar position="fixed">
        <Toolbar>
          {!home && (
            <IconButton edge="start" color="inherit" onClick={() => toggleMenu(true)}>
              <Icon>menu</Icon>
            </IconButton>
          )}
          <Typography position="fixed" className="flex-grow-1">
            <b>{title ? title : `${Config.schoolName}學校家課管理`}</b>
          </Typography>
          <Typography position="fixed">
            <b>{displayName}</b>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer classes={{ paper: 'drawer-paper' }} className="drawer" open={openMenu} onClose={() => toggleMenu(false)}>
        <List>
          <ListItem>
            <ListItemIcon>
              <Icon>account_circle</Icon>
            </ListItemIcon>
            <ListItemText primary={displayName} />
          </ListItem>
          <Divider />
          {/* eslint-disable-next-line array-callback-return */}
          {menu.map(({ key, link, icon, text, roles, value = null }) => {
            if (roles.indexOf(userRole) >= 0)
              return (
                <Link to={link} key={key} className="link" onClick={() => setOpenMenu(false)}>
                  <ListItem button>
                    <ListItemIcon>
                      <Icon>{icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={`${text}${!!value ? ` (${value})` : ''}`} />
                  </ListItem>
                </Link>
              );
          })}
          <Divider />
          {/* eslint-disable-next-line array-callback-return */}
          {adminMenu.map(({ key, link, icon, text, display = false }) => {
            if (display) {
              return (
                <Link to={link} key={key} className="link" onClick={() => setOpenMenu(false)}>
                  <ListItem button>
                    <ListItemIcon>
                      <Icon>{icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                </Link>
              )
            }
          })
          }
          <Divider />
          <ListItem button onClick={onLogout}>
            <ListItemIcon>
              <Icon>exit_to_app</Icon>
            </ListItemIcon>
            <ListItemText primary="登出" />
          </ListItem>
        </List>
      </Drawer>
    </Fragment>
  );
};
export default Nav;
