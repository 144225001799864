import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const styles = makeStyles((theme) => ({
	root: {},
}));

const ResubmitRow = ({ resubmits = [] }) => {
	return (
		<Box mt={1} display="flex" flexWrap="wrap" alignItems="center">
			{resubmits.map(({ id, class_number, student_chi_name }, index) => (
				<Box key={index} mr={1} mb={1}>
					<Chip clickable color="primary" variant="outlined"  label={student_chi_name} />
				</Box>
			))}
		</Box>
	);
};

export default ResubmitRow;
