import { createTheme } from '@material-ui/core/styles';

const Theme = createTheme({
	palette: {
		primary: {
			main: '#1c84c6',
		},
		secondary: {
			main: '#ed5565',
		},
		success: {
			main: '#1ab394',
			contrastText: 'white',
		},
	},
	boxShadow: '0px 4px 25px rgba(133, 133, 133, 0.2)',
	borderRadius: 15,
});

export default Theme;
