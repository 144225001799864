import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuth, selectRole } from '../redux/user_slice';

const PrivateRoute = ({ path, redirectTo = '/', role = [], children, antiLogin = false, ...props }) => {
	const isAuth = useSelector(selectIsAuth);
	const userRole = useSelector(selectRole);

	const roleValid = () => {
		if (role.length > 0 && role.indexOf(userRole) < 0) return false;
		return true;
	};

	return (
		<Route {...props} path={path}>
			{(!antiLogin && isAuth && roleValid()) || (antiLogin && !isAuth) ? children : <Redirect to={redirectTo} />}
		</Route>
	);
};

export default PrivateRoute;
