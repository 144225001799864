import React, { Fragment, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { get } from '../../../helpers/ApiHelper';
import { getCurrentYearId, tomorrow, yesterday } from '../../../helpers/TimeHelper';
import { getDate, now } from '../../../helpers/TimeHelper';
import { filter } from 'lodash';
import Nav from '../../../components/Nav';
import Loader from '../../../components/Loader';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedYear, setSelectedYear } from '../../../redux/user_slice';
import CourseCard from './_partials/CourseCard';

const CourseList = () => {
	const theme = useTheme();
	const matchXsDown = useMediaQuery(theme.breakpoints.down('xs'));

	const dispatch = useDispatch();
	const selectedYear = useSelector(selectSelectedYear)?.toString() || '';

	const [isLoading, setIsLoading] = useState(true);
	const [years, setYears] = useState([]);
	const [teacherClasses, setTeacherClasses] = useState([]);
	const [teacherSubjects, setTeacherSubjects] = useState([]);
	const [classHomeworks, setClassHomeworks] = useState([]);
	const [subjectHomeworks, setSubjectHomeworks] = useState([]);
	const [selectedDate, setSelectedDate] = useState(now());

	useEffect(() => {
		const fetchYear = async () => {
			const { status, result } = await get('getYear');
			if (status !== false && result !== undefined) {
				setYears(result);
				if (!selectedYear) dispatch(setSelectedYear(getCurrentYearId(result)));
			}
		};
		fetchYear();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			if (selectedYear) {
				const formattedDate = getDate(selectedDate);

				const teacherClass = await get(`getPersonalTeacherClassByYear/${selectedYear}`);
				if (teacherClass.status !== false && teacherClass.result !== undefined) setTeacherClasses(teacherClass.result);

				const teacherSubject = await get(`getPersonalTeacherSubjectByYear/${selectedYear}`);
				if (teacherSubject.status !== false && teacherSubject.result !== undefined) {
					setTeacherSubjects(teacherSubject.result);
				}

				const classHomework = await get(`getPersonalTeacherClassHomeworkByYear/submit/${selectedYear}/${formattedDate}`);
				if (classHomework.status !== false && classHomework.result !== undefined) setClassHomeworks(classHomework.result);

				const subjectHomework = await get(`getPersonalTeacherSubjectHomeworkByYear/submit/${selectedYear}/${formattedDate}`);
				if (subjectHomework.status !== false && subjectHomework.result !== undefined) setSubjectHomeworks(subjectHomework.result);
			}
			setIsLoading(false);
		};

		fetchData();
	}, [selectedYear, selectedDate]);

	const onChangeYear = (selectedYear) => {
		dispatch(setSelectedYear(selectedYear));
	};

	return (
		<Fragment>
			<Loader isLoading={isLoading} />

			<div className="root">
				<Nav title="課程列表" />

				<Container className="root-content">
					<ToolbarBuffer />

					<Box mb={4} display="flex" alignItems="center" justifyContent={matchXsDown ? 'center' : 'flex-start'}>
						<Icon className="pointer" onClick={() => setSelectedDate(yesterday(selectedDate))}>
							arrow_back_ios
						</Icon>
						<Box mx={2} display="flex" flexDirection={matchXsDown ? 'column' : 'row'} alignItems="center">
							<FormControl variant="outlined" fullWidth={matchXsDown}>
								<InputLabel id="class">年度</InputLabel>
								<Select labelId="class" value={selectedYear} labelWidth={35} onChange={(event) => onChangeYear(event.target.value)}>
									{years.map((year, index) => (
										<MenuItem key={index} value={year.id}>
											{year.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Box m={1} />
							<KeyboardDatePicker
								autoOk
								inputVariant="outlined"
								label="功課遞交日期"
								format="YYYY-MM-DD"
								value={selectedDate}
								onChange={(selectedDate) => setSelectedDate(selectedDate.format())}
							/>
						</Box>
						<Icon className="pointer" onClick={() => setSelectedDate(tomorrow(selectedDate))}>
							arrow_forward_ios
						</Icon>
					</Box>

					<Box mb={3}>
						{teacherClasses.length === 0 && teacherSubjects.length === 0 ? (
							<Box>今年度沒有管理班別</Box>
						) : (
							<Fragment>
								<Grid container spacing={5}>
									{teacherClasses.length > 0 &&
										teacherClasses.map((teacherClass, index) => {
											const filteredHomeworks = filter(classHomeworks, {
												class_id: teacherClass.class_id,
											});
											return (
												<Grid key={index} item xs={12} sm={6} md={4}>
													<CourseCard courseDetails={teacherClass} filteredHomeworks={filteredHomeworks} />
												</Grid>
											);
										})}

									{teacherSubjects.length > 0 &&
										teacherSubjects.map((teacherSubject, index) => {
											const filteredHomeworks = filter(subjectHomeworks, {
												class_id: teacherSubject.class_id,
												subject_id: teacherSubject.subject_id,
											});
											return (
												<Grid key={index} item xs={12} sm={6} md={4}>
													<CourseCard courseDetails={teacherSubject} filteredHomeworks={filteredHomeworks} isSubject />
												</Grid>
											);
										})}
								</Grid>
							</Fragment>
						)}
					</Box>
				</Container>
			</div>
		</Fragment>
	);
};

export default CourseList;
