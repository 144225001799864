import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import Nav from '../../../components/Nav';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import Container from '@material-ui/core/Container';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import SectionPaper from '../../../components/SectionPaper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import { getDate } from '../../../helpers/TimeHelper';
import TableBody from '@material-ui/core/TableBody';
import { get, post } from '../../../helpers/ApiHelper';
import Box from '@material-ui/core/Box';
import Button from '../../../components/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useSelector } from 'react-redux';
import { selectSelectedYear } from '../../../redux/user_slice';

const styles = makeStyles((theme) => ({
	root: {},
	header: {
		fontWeight: 600,
	},
	table: {
		minWidth: '100%',
	},
	tableHeader: {
		fontWeight: 600,
	},
	tableRow: {
		cursor: 'pointer',
	}
}));

const AnalysisTeacherDetail = (props) => {
	const classes = styles();
	const history = useHistory();

	const selectedYear = useSelector(selectSelectedYear)?.toString() || '';
	const { studentId } = useParams();

	const [analysis, setAnalysis] = useState([]);
	const [page, setPage] = useState(0);
	const [student, setStudent] = useState({});
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		const fetch = async () => {
			const student = await get(`getOneStudentByStudentId/${selectedYear}/${studentId}`);
			const studentStatus = student.status !== false && student.result !== undefined;
			setStudent(studentStatus? student.result[0] : {});

			const analysis = await post(`getOutstandingAnalysis`, {  year_id: selectedYear, student_id: studentId });
			const { status: analysisStatus, result: analysisResult } = analysis;
			setAnalysis(analysisStatus ? analysisResult : []);
		};

		if(studentId) fetch();
	}, [studentId])

	const back = () => {
		history.goBack();
	}

	const changePage = (event, newPage) => {
		setPage(newPage);
	};

	const changeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const PagingComponent = () => (
		<TablePagination
			labelRowsPerPage="每頁顯示"
			rowsPerPageOptions={[10, 20, 30]}
			component="div"
			count={analysis.length}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={changePage}
			onRowsPerPageChange={changeRowsPerPage}
		/>
	);

	const tableHeaders = [
		{ key: 'id', label: '編號' },
		{ key: 'student_key', label: '學生編號' },
		{ key: 'belongs', label: '所屬班別' },
		{ key: 'student_name', label: '名稱' },
		{ key: 'subject_name', label: '科目' },
		{ key: 'description', label: '內容' },
		{ key: 'homework_create_date', label: '派發日期', type: 'date' },
		{ key: 'homework_submit_date', label: '遞交日期', type: 'date' },
		{ key: 'outstanding_submit_date', label: '補交日期', type: 'date' },
	];

	return (
		<Fragment>
			<div className="root">
				<Nav title="數據分析" />
				<Container className="root-content">
					<ToolbarBuffer />
					<Box display="flex" alignItems="center">
						<Button startIcon={<ChevronLeftIcon />} onClick={back}>
							上一頁
						</Button>
						<Box mx={3}>
							<Typography className={classes.header}>
								{student['chi_name']}
							</Typography>
						</Box>
					</Box>
					<SectionPaper>
						<PagingComponent />
						<TableContainer>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										{tableHeaders.map(({ key, label }) => (
											<TableCell key={key}>
												<Typography className={classes.tableHeader}>{label}</Typography>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{analysis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
										<TableRow key={index}>
											{tableHeaders.map(({ key, type  }) => {
												let value = row[key];
												if (key === 'student_name') value = row['student_chi_name'] || row['student_eng_name'];
												if (type === 'date') value = !!value ? getDate(value) : '-';
												return (
													<TableCell key={key}>
														<Typography>{value}</Typography>
													</TableCell>
												);
											})}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<PagingComponent />
					</SectionPaper>
				</Container>
			</div>
		</Fragment>
	)
};

export default AnalysisTeacherDetail;