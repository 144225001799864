import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { getDate } from '../../../../helpers/TimeHelper';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AlarmIcon from '@material-ui/icons/Alarm';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const styles = makeStyles((theme) => ({
	root: {},
	summary: {
		fontWeight: 600,
		marginLeft: theme.spacing(1),
	},
	itemTitle: {
		fontWeight: 600,
		marginRight: theme.spacing(1),
	},
	primary: {
		color: theme.palette.primary.main,
	},
	secondary: {
		color: theme.palette.secondary.main,
	},
	success: {
		color: theme.palette.success.main,
	},
}));

const StudentHomeworkTable = ({ homeworks = [], outstandings = [], isOutstanding = false, isResubmit = false }) => {
	const classes = styles();

	const { type } = useParams();
	let emptyMsg = '沒有功課記錄';
	if (type === 'outstanding') emptyMsg = '沒有仍欠交功課';
	if (type === 'resubmit') emptyMsg = '沒有已補交功課';

	return (
		<React.Fragment>
			{homeworks.length > 0 ? (
				homeworks.map((homework, index) => {
					const {
						id,
						subject_name,
						homework_type_name,
						description,
						create_date,
						submit_date,
						collected,
						teacher_chi_name,
						teacher_eng_name,
					} = homework;

					let status = collected ? '已遞交' : '未進行點收';
					let color = collected ? 'success' : '';
					let record = outstandings.find((outstanding) => outstanding.homework_id === id);
					if (isOutstanding || (!!record && !record?.is_submitted)) {
						status = '欠交';
						color = 'secondary';
					}
					if (isResubmit || (!!record && record.is_submitted)) {
						status = `已補交 ${!!record ? `(${getDate(record.submit_date)})` : ''}`;
						color = 'primary';
					}

					const details = [
						{ title: '派發老師', value: teacher_chi_name || teacher_eng_name },
						{ title: '科目', value: subject_name },
						{ title: '種類', value: homework_type_name },
						{ title: '描述', value: description },
						{ title: '派發日期', value: getDate(create_date) },
						{ title: '遞交日期', value: getDate(submit_date) },
					];

					const summaries = [
						{
							icon: <DescriptionIcon className={classes.success} />,
							value: `${subject_name}: ${homework_type_name ? `[${homework_type_name}] ` : ''}${description}`,
							sm: 5,
						},
						{
							icon: <AccountCircleIcon />,
							value: teacher_chi_name || teacher_eng_name,
							sm: 2,
						},
						{
							icon: <AlarmIcon />,
							value: !!submit_date ? getDate(submit_date) : null,
							sm: 2,
						},
						{
							icon: <AssignmentIndIcon />,
							value: status,
							sm: 2,
							color,
						},
					];

					return (
						<Accordion key={index}>
							<AccordionSummary className="expand-header expand-padding">
								<Grid container spacing={2}>
									{summaries.map(({ icon, value, sm, color = null }, index) => (
										<Grid key={index} item xs={12} sm={sm}>
											<Box display="flex" alignItems="center">
												{icon}
												<Typography className={[classes.summary, color ? classes[color] : ''].join(' ')}>{value}</Typography>
											</Box>
										</Grid>
									))}
								</Grid>
							</AccordionSummary>
							<AccordionDetails className="expand-padding">
								<Box p={1} flex={1}>
									{details.map(({ title, value = null }, index) => (
										<Box key={index} mb={2} display="flex">
											<Typography variant="body1" className={classes.itemTitle}>
												{title}:
											</Typography>
											<Typography variant="body1">{value || '-'}</Typography>
										</Box>
									))}
								</Box>
							</AccordionDetails>
						</Accordion>
					);
				})
			) : (
				<Box>
					<Alert severity="info">{emptyMsg}</Alert>
				</Box>
			)}
		</React.Fragment>
	);
};

export default StudentHomeworkTable;
