import React, { Fragment, useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Nav from '../../components/Nav';
import Loader from '../../components/Loader';
import ToolbarBuffer from '../../components/ToolbarBuffer';
import { get } from '../../helpers/ApiHelper';
import { getCurrentYearId } from '../../helpers/TimeHelper';
import { useDispatch, useSelector } from 'react-redux';
import { selectClassId, selectSelectedYear, selectStudentClassId, setClassId, setSelectedYear, setStudentClassId } from '../../redux/user_slice';
import StudentHomeworkTable from './dashboard/_partials/StudentHomeworkTable';
import { OutlinedInput } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useParams } from 'react-router-dom';

const Record = () => {
	const theme = useTheme();
	const matchXsDown = useMediaQuery(theme.breakpoints.down('xs'));

	const { type } = useParams();
	const isOutstanding = type === 'outstanding';

	const dispatch = useDispatch();
	const selectedYear = useSelector(selectSelectedYear)?.toString() || '';
	const studentClassId = useSelector(selectStudentClassId);
	const classId = useSelector(selectClassId);

	const [isLoading, setIsLoading] = useState(false);
	const [years, setYears] = useState([]);
	const [students, setStudents] = useState([]);
	const [homeworks, setHomeworks] = useState([]);

	const fetchYear = async () => {
		setIsLoading(true);

		const { status, result } = await get('getYear');
		if (status !== false && result !== undefined) {
			setYears(result);
			if (!selectedYear) dispatch(setSelectedYear(getCurrentYearId(result)));
		}

		setIsLoading(false);
	};

	const fetchStudent = async () => {
		setIsLoading(true);

		const { status, result } = await get(`getOneStudentByYear/${selectedYear}`);
		if (status !== false && result.length > 0) {
			setStudents(result);
		} else {
			dispatch(setStudentClassId(null));
			dispatch(setClassId(null));
			setStudents([]);
			setHomeworks([]);
		}

		setIsLoading(false);
	};

	const fetchHomework = async () => {
		setIsLoading(true);

		const classIds = !!classId ? [classId] : students.map(({ class_id }) => class_id);
		const query = isOutstanding ? 'getPersonalOutstandingByClass' : 'getPersonalResubmitByClass';
		const homeworks = await get(`${query}/${classIds}`);
		const homeworksStatus = homeworks.status !== false && homeworks.result !== undefined;
		setHomeworks(homeworksStatus ? homeworks.result : []);

		setIsLoading(false);
	};

	useEffect(() => {
		fetchYear();
	}, []);

	useEffect(() => {
		if (selectedYear) fetchStudent();
	}, [selectedYear]);

	useEffect(() => {
		if (students.length > 0) fetchHomework();
	}, [classId, students, type]);

	const onChangeYear = (selectedYear) => {
		dispatch(setSelectedYear(selectedYear));
	};

	const onChangeClass = (studentClassId) => {
		if (studentClassId) {
			const student = students.find(({ student_class_id }) => student_class_id === studentClassId);
			const { class_id } = student;
			dispatch(setStudentClassId(studentClassId));
			dispatch(setClassId(class_id));
		} else {
			dispatch(setStudentClassId(null));
			dispatch(setClassId(null));
		}
	};

	const getStudentInfo = () => {
		if (studentClassId) {
			const student = students.find(({ student_class_id }) => student_class_id === studentClassId);
			if (!!student) {
				const { class_name, chi_name, eng_name, class_number } = student || {};
				return `${class_name} ${chi_name || eng_name} (${class_number})`;
			}
		} else {
			const student = students[0] || null;
			if (!!student) {
				const { chi_name, eng_name } = student || {};
				return chi_name || eng_name;
			}
		}
		return '未有學生資料';
	};

	return (
		<Fragment>
			<Loader isLoading={isLoading} />

			<div className="root">
				<Nav title={isOutstanding ? '仍欠交功課' : '已補交功課'} />

				<Container className="root-content">
					<ToolbarBuffer />

					<Box mb={4} display="flex" alignItems="center" justifyContent={matchXsDown ? 'center' : 'flex-start'}>
						<Box mx={2} display="flex" flexDirection={matchXsDown ? 'column' : 'row'} alignItems="center">
							<FormControl variant="outlined" fullWidth={matchXsDown}>
								<InputLabel id="year">年度</InputLabel>
								<Select labelId="year" value={selectedYear} labelWidth={35} onChange={(event) => onChangeYear(event.target.value)}>
									{years.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Box m={1} />
							<FormControl variant="outlined" fullWidth={matchXsDown}>
								<InputLabel id="class" shrink>
									班級
								</InputLabel>
								<Select
									labelId="class"
									displayEmpty
									value={studentClassId || ''}
									labelWidth={35}
									input={<OutlinedInput notched label="班級" />}
									onChange={(event) => {
										onChangeClass(event.target.value);
									}}
								>
									<MenuItem value="">全部班別</MenuItem>
									{students.map(({ student_class_id, class_name }) => (
										<MenuItem key={student_class_id} value={student_class_id}>
											{class_name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Box m={1} />
							{getStudentInfo()}
						</Box>
					</Box>
					<StudentHomeworkTable homeworks={homeworks} isOutstanding={isOutstanding} isResubmit={!isOutstanding} />
				</Container>
			</div>
		</Fragment>
	);
};

export default Record;
