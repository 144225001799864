import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import MomentUtils from '@date-io/moment';

// theme
import { ThemeProvider } from '@material-ui/styles';
import Theme from './styles/theme/Theme';

// datepicker
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/zh-hk';

// redux
import { Provider as ReduxProvider } from 'react-redux';
import ReduxStore from '../src/redux/store';

// routes
import Routes from './routes/Routes';

import Config from './const/Config';

function App() {
  return (
    <ReduxProvider store={ReduxStore}>
      <GoogleOAuthProvider clientId={Config.clientId}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={Theme}>
            <Routes />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </GoogleOAuthProvider>
    </ReduxProvider>
  );
}

export default App;
