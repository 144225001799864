import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import {
	AppBar,
	Box, Checkbox,
	Container,
	Fab,
	FormControl,
	FormControlLabel,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Toolbar,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ErrorBox from '../../../../components/ErrorBox';
import { get, post } from '../../../../helpers/ApiHelper';
import { getDate, now } from '../../../../helpers/TimeHelper';
import socketIOClient from 'socket.io-client';
import Config from '../../../../const/Config';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';

const EditHomeworkDialog = forwardRef(({ classInfo = {}, fetchHomework = () => {} }, ref) => {
	const socket = useRef();

	const { classId, subject_id = null } = useParams();
	const [open, setOpen] = useState(false);
	const [loading, setIsLoading] = useState(false);

	const [subjects, setSubjects] = useState([]);
	const [homeworkTypes, setHomeworkTypes] = useState([]);

	const [details, setDetails] = useState({});
	const [errors, setErrors] = useState([]);

	const fetchInfo = async () => {
		setIsLoading(true);

		const subjects = await get('getSubject/');
		if (subjects.status !== false && subjects.result !== undefined) {
			setSubjects(subjects.result);
		}

		const homeworkTypes = await get('getHomeworkType/');
		if (homeworkTypes.status !== false && homeworkTypes.result !== undefined) {
			setHomeworkTypes(homeworkTypes.result);
		}

		setIsLoading(false);
	};

	useEffect(() => {
		socket.current = socketIOClient(Config.host);
	}, []);

	useEffect(() => {
		fetchInfo();
	}, []);

	const onClose = () => setOpen(false);
	useImperativeHandle(ref, () => ({
		open: (homework = {}) => {
			const { is_count = 1, ...records } = homework;
			setDetails({
				classId,
				subject_id: !!subject_id ? parseInt(subject_id) : null,
				create_date: now(),
				submit_date: now().add(1, 'days'),
				is_count: !!is_count,
				...records,
			});
			setOpen(true);
		},
	}));

	const updateDetails = (key, value) =>
		setDetails((details) => ({
			...details,
			[key]: value,
			homework_type_id: key === 'subject_id' ? '' : key === 'homework_type_id' ? value : details.homework_type_id,
		}));

	const verify = () => {
		let errors = [];
		const { classId, create_date, subject_id, description, submit_date } = details;
		if (!classId) errors.push('必需輸入班別');
		if (!create_date) errors.push('必需派發日期');
		if (!subject_id) errors.push('必需輸入科目');
		if (!description) errors.push('必需輸入描述');
		if (!submit_date) errors.push('必需輸入遞交日期');
		setErrors(errors);
		return errors.length === 0;
	};

	const createVariables = () => {
		const { id = null, classId, create_date, subject_id, homework_type_id, description, submit_date, is_count } = details;
		return {
			id,
			create_date: getDate(create_date),
			class_id: classId,
			subject_id,
			homework_type_id,
			description,
			submit_date: getDate(submit_date),
			is_count,
		};
	};

	const save = () => {
		if (!verify()) return;
		setIsLoading(true);
		post('editHomework', { year_id: classInfo.year_id, edit_homework: createVariables() }).then(({ status }) => {
			if (status) {
				socket.current.emit('edit');
				fetchHomework();
				onClose();
				setIsLoading(false);
			}
		});
	};

	return (
		<React.Fragment>
			<Loader loading={loading} />
			<Dialog fullScreen open={open} onClose={onClose}>
				<AppBar className="dialog-app-bar">
					<Toolbar>
						<IconButton color="inherit" onClick={onClose}>
							<Icon>close</Icon>
						</IconButton>
						<Typography variant="h6" className="dialog-title">
							編輯家課
						</Typography>
					</Toolbar>
				</AppBar>
				<Box mt={3} mx={3}>
					<Container>
						<Box display="flex" flexWrap="wrap">
							<Box mb={3} mr={3} display="flex" alignItems="center">
								<Icon className="margin-right-10">group</Icon>
								<Box mr={1}>
									<b>班別:</b>
								</Box>
								{classInfo.class_name}
							</Box>
						</Box>

						<Box mb={3}>
							<KeyboardDatePicker
								autoOk
								inputVariant="outlined"
								label="派發日期*"
								format="YYYY-MM-DD"
								fullWidth
								value={details.create_date}
								onChange={(date) => updateDetails('create_date', date)}
							/>
						</Box>

						<Box mb={3}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="subject">科目*</InputLabel>
								<Select
									labelId="subject"
									value={details.subject_id || ''}
									labelWidth={35}
									onChange={(event) => updateDetails('subject_id', event.target.value)}
									disabled={!!subject_id}
								>
									{subjects.map((subject, index) => (
										<MenuItem key={index} value={subject.id}>
											{subject.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						{homeworkTypes.filter((homeworkType) => homeworkType.subject_id === details.subject_id).length > 0 && (
							<Box mb={3}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="class">家課種類</InputLabel>
									<Select
										labelId="class"
										value={details.homework_type_id || ''}
										labelWidth={65}
										onChange={(event) => updateDetails('homework_type_id', event.target.value)}
									>
										<MenuItem value="">不適用</MenuItem>
										{homeworkTypes
											.filter((homeworkType) => homeworkType.subject_id === details.subject_id)
											.map((homeworkType, index) => (
												<MenuItem key={index} value={homeworkType.id}>
													{homeworkType.name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Box>
						)}

						<Box mb={3}>
							<TextField
								label="描述*"
								variant="outlined"
								fullWidth
								value={details.description || ''}
								onChange={(event) => updateDetails('description', event.target.value)}
							/>
						</Box>

						<Box mb={3}>
							<KeyboardDatePicker
								autoOk
								inputVariant="outlined"
								label="遞交日期*"
								format="YYYY-MM-DD"
								fullWidth
								value={details.submit_date}
								onChange={(date) => updateDetails('submit_date', date)}
							/>
						</Box>

						<Box mb={3}>
							<FormControlLabel
								checked={details.is_count || false}
								control={<Checkbox/>}
								label="計算欠交記錄*"
								onChange={(event) => updateDetails('is_count', event.target.checked)}
							/>
						</Box>

						<Box mb={3} display={errors.length > 0 ? 'block' : 'none'}>
							<ErrorBox errors={errors} />
						</Box>

						<Box display="flex" justifyContent="center" alignItems="center">
							<Box mr={3}>
								<Fab variant="extended" size="medium" onClick={onClose}>
									<Icon>close</Icon>取消
								</Fab>
							</Box>
							<Box>
								<Fab variant="extended" color="primary" size="medium" onClick={save}>
									<Icon>save</Icon>儲存
								</Fab>
							</Box>
						</Box>
					</Container>
				</Box>
			</Dialog>
		</React.Fragment>
	);
});

export default EditHomeworkDialog;
