import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Chip } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	root: {},
	homeworkList: {
		overflowY: 'auto',
	},
}));
const BlackboardCollectList = ({ homeworks = [], outstandings = [] }) => {
	const classes = styles();

	return (
		<Box className="full-height" display="flex" flexDirection="column">
			{homeworks.length > 0 ? (
				<Fragment>
					<Box flexGrow={1} flexShrink={1} className={classes.homeworkList}>
						{homeworks.map(({ id, subject_name, homework_type_name, description, collected }, index) => (
							<React.Fragment>
								<Box p={1} key={id} display="flex" alignItems="center" className="blackboard-dark-backgroud">
									<Box px={2}>
										<Typography variant="h5">{index + 1}</Typography>
									</Box>
									<Box flexGrow="1" borderLeft={1} pl={2} alignItems="center">
										<Box mb={1} className="collect-box-text">
											<Typography variant="h4">{`${homework_type_name ? `[${homework_type_name}] ` : ''}${description}`}</Typography>
										</Box>
										{collected ? (
											outstandings.filter((outstanding) => outstanding.homework_id === id).length > 0 ? (
												<Box display="flex" flexWrap="wrap" alignContent="flex-start" mt={2}>
													{outstandings
														.filter((outstanding) => outstanding.homework_id === id)
														.map((outstanding, index) => (
															<Chip key={index} className="collect-box-chip" color="secondary" label={outstanding.class_number} />
														))}
												</Box>
											) : (
												<span>已全部遞交</span>
											)
										) : (
											<span>未進行點收</span>
										)}
									</Box>
								</Box>
								<hr />
							</React.Fragment>
						))}
					</Box>
					{/*<Box display="flex" alignItems="center">*/}
					{/*	<Box flexWrap="nowrap" pr={1}>*/}
					{/*		<Typography variant="h6">點收:</Typography>*/}
					{/*	</Box>*/}
					{/*	<Box display="flex" flexGrow={2} flexWrap="wrap">*/}
					{/*		{homeworks.map((homework, index) => (*/}
					{/*			<Box pr={1} pt={1} pb={1} key={index}>*/}
					{/*				<Fab*/}
					{/*					color="primary"*/}
					{/*					size="small"*/}
					{/*					// onClick={() => handleCollectDrawer(true, homework.id, homework.description, homework.subject_name)}*/}
					{/*				>*/}
					{/*					{index + 1}*/}
					{/*				</Fab>*/}
					{/*			</Box>*/}
					{/*		))}*/}
					{/*	</Box>*/}
					{/*</Box>*/}
				</Fragment>
			) : (
				<Box p={2} mt={5} textAlign="center">
					<Typography variant="h4">今天沒有需要點收之家課</Typography>
				</Box>
			)}
		</Box>
	);
};

export default BlackboardCollectList;
