import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '../../../../components/Button';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { post } from '../../../../helpers/ApiHelper';
import { getDate, now } from '../../../../helpers/TimeHelper';
import socketIOClient from 'socket.io-client';
import Config from '../../../../const/Config';
import Loader from '../../../../components/Loader';
import ClearIcon from '@material-ui/icons/Clear';

const AbsentRow = ({ homeworkId, absents = [], fetchRecord = () => {} }) => {
	const socket = useRef();

	const [loading, setLoading] = useState(false);
	const [selectedIds, setSelectedIds] = useState([]);
	const [studentClassIds, setStudentClassIds] = useState([]);

	useEffect(() => {
		socket.current = socketIOClient(Config.host);
	}, []);

	const handleSelect = (id, studentClassId) => {
		const index = selectedIds.indexOf(id);
		if (index !== -1) {
			setSelectedIds((selectedIds) => [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1)]);
			setStudentClassIds((studentClassIds) => [...studentClassIds.slice(0, index), ...studentClassIds.slice(index + 1)]);
		} else {
			setSelectedIds((selectedIds) => [...selectedIds, id]);
			setStudentClassIds((studentClassIds) => [...studentClassIds, studentClassId]);
		}
	};

	const handleOutstanding = () => {
		const data = {
			homeworkId,
			submitDate: getDate(now()),
			absentIds: selectedIds,
			outstandingStudentClassIdsIds: studentClassIds
		};

		setLoading(true);
		post('resubmitOutstandingHomework', data).then(({ status }) => {
			if (status) {
				socket.current.emit('edit');
				setSelectedIds([]);
				setStudentClassIds([]);
				fetchRecord();
				setLoading(false);
			}
		});
	}
	const handleResubmit = () => {
		const data = {
			homeworkId,
			submitDate: getDate(now()),
			absentIds: selectedIds,
		};

		setLoading(true);
		post('resubmitAbsentHomework', data).then(({ status }) => {
			if (status) {
				socket.current.emit('edit');
				setSelectedIds([]);
				setStudentClassIds([]);
				fetchRecord();
				setLoading(false);
			}
		});
	};

	return (
		<React.Fragment>
			<Loader loading={loading} />
			<Box mt={1} display="flex" flexWrap="wrap" alignItems="center">
				{absents.map(({ id, student_class_id,  class_number, student_chi_name }, index) => (
					<Box key={index} mr={1} mb={1}>
						<Chip
							clickable
							className="success"
							variant="outlined"
							label={student_chi_name}
							onClick={() => handleSelect(id, student_class_id)}
							onDelete={() => handleSelect(id, student_class_id)}
							deleteIcon={selectedIds.indexOf(id) >= 0 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
						/>
					</Box>
				))}
			</Box>
			<Box mt={1} display="flex" flexWrap="wrap" alignItems="center">
				{selectedIds.length > 0 && (
					<Box mr={1} mb={1}>
						<Button variant="contained" color="primary" startIcon={<DoneAllIcon />} onClick={handleResubmit}>
							確認補交
						</Button>
					</Box>
				)}
				{selectedIds.length > 0 && (
					<Box mr={1} mb={1}>
						<Button variant="contained" color="secondary" startIcon={<ClearIcon />} onClick={handleOutstanding}>
							確認欠交
						</Button>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
};

export default AbsentRow;
