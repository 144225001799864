// React
import React, { Component, Fragment } from 'react';

// Material ui
import {
	Hidden,
	Container,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Fab,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableCell,
	TableSortLabel,
	TableBody,
	TableRow,
	Icon,
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

// Helper
import { get, post } from '../../../helpers/ApiHelper';
import { getCurrentYearId } from '../../../helpers/TimeHelper';
import { excelTemplate } from '../../../helpers/ExcelHelper';

// Lodash
import { map, uniq, uniqBy, find, compact, orderBy, filter, sortBy } from 'lodash';

// import XLSX
import XLSX from 'xlsx';

// Component
import Nav from '../../../components/Nav';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import ErrorBox from '../../../components/ErrorBox';
import TableNoData from '../../../components/TableNoData';
import Loader from '../../../components/Loader';

class TeacherSubjectSetting extends Component {
	state = {
		isLoading: true,

		years: [],
		classNames: [],
		teacher_subjects: [],
		showTeacherSubjects: [],

		selectedYear: '',
		selectedClass: 'allClass',

		table_columns: [
			{ property: 'class_name', name: '班別', sort: true },
			{ property: 'subject_id', name: '科目', sort: true },
			{ property: 'chi_name', name: '老師', sort: true },
		],
		sort_column: null,
		sort_order: 'desc',

		open_edit_dialog: false,
		teacher_subject_file: [],
		edit_teacher_subject_errors: [],

		header_format: ['班別', '科目', '老師電郵', '老師中文姓名'],
	};

	fetchYear = (callback) => {
		let getYear = () =>
			get('getYear').then(({ status, result }) => {
				if (status !== false && result !== undefined) {
					const selectedYear = getCurrentYearId(result);
					this.setState({
						years: result,
						selectedYear,
					});
				}
			});

		Promise.all([getYear()]).then(() => {
			if (callback) {
				callback();
			}
		});
	};

	fetch = (callback) => {
		const { selectedYear } = this.state;

		let getTeacherSubject = () =>
			get('getTeacherSubjectByYear/' + selectedYear).then(({ status, result }) => {
				if (status !== false && result !== undefined) {
					this.setState({
						teacher_subjects: result,
						showTeacherSubjects: result,
						classNames: uniq(map(sortBy(result, ['class_name']), 'class_name')),
					});
				}
			});

		Promise.all([getTeacherSubject()]).then(() => {
			if (callback) {
				callback();
			}
		});
	};

	sorting = (new_sort_column = 'class_name', new_sort_order = 'asc') => {
		const { showTeacherSubjects, sort_column, sort_order } = this.state;

		if (new_sort_column === sort_column) {
			if (sort_order === 'asc') {
				new_sort_order = 'desc';
			}
		}

		this.setState({ isLoading: true });
		let newTeachers = orderBy(showTeacherSubjects, [new_sort_column], [new_sort_order]);

		this.setState({
			showTeacherSubjects: newTeachers,
			sort_column: new_sort_column,
			sort_order: new_sort_order,
			isLoading: false,
		});
	};

	componentDidMount = () => {
		this.fetchYear(() => {
			this.fetch(() => {
				this.sorting();
			});
		});
	};

	onChangeYear = (selectedYear) => {
		this.setState(
			{
				isLoading: true,
				selectedYear,
			},
			() => {
				this.fetch(() => {
					this.setState({ isLoading: false });
				});
			}
		);
	};

	onChangeClass = (selectedClass) => {
		this.setState(
			{
				selectedClass,
				showTeacherSubjects:
					selectedClass === 'allClass' ? this.state.teacher_subjects : filter(this.state.teacher_subjects, { class_name: selectedClass }),
			},
			() => {
				this.sorting();
			}
		);
	};

	handleEditDialog = (status) => {
		this.setState({
			open_edit_dialog: status,
			teacher_subject_file: [],
			edit_teacher_subject_errors: [],
		});
	};

	onUploadFile = (teacher_subject_file) => {
		this.setState({ teacher_subject_file });
	};

	save = () => {
		const { fetch, sorting } = this;
		const { selectedYear, teacher_subject_file, header_format } = this.state;
		let edit_teacher_subject_errors = [];

		if (teacher_subject_file.length === 0) {
			edit_teacher_subject_errors.push('必需以XLSX格式上載老師科目資料');
		}

		if (edit_teacher_subject_errors.length > 0) {
			this.setState({ edit_teacher_subject_errors });
		} else {
			const file = teacher_subject_file[0];
			const reader = new FileReader();
			reader.onload = (e) => {
				this.setState({ isLoading: true });

				const workbook = XLSX.read(e.target.result, { type: 'array' });
				const worksheet = workbook.Sheets[workbook.SheetNames[0]];

				// [class, subject, email, chi_name]
				const header_data = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });
				const header = header_data[0];

				if (JSON.stringify(header) !== JSON.stringify(header_format)) {
					edit_teacher_subject_errors.push('請參照範本格式上載老師科目資料');
					this.setState({
						edit_teacher_subject_errors,
						isLoading: false,
					});
				} else {
					const teacher_subject_data = XLSX.utils.sheet_to_json(worksheet, {
						header: 1,
						range: 1,
						blankrows: false,
					});
					const edit_teacher_subject = {
						year_id: selectedYear,
						class_data: compact(
							uniqBy(
								map(teacher_subject_data, (data) => {
									if (data[0]) {
										return {
											year_id: selectedYear,
											name: data[0],
										};
									}
								})
							)
						),
						subject_data: compact(
							uniqBy(
								map(teacher_subject_data, (data) => {
									if (data[1]) {
										return {
											name: data[1],
										};
									}
								})
							)
						),
						teacher_subject_data: map(teacher_subject_data, (data) => {
							return {
								year_id: selectedYear,
								class: data[0],
								subject: data[1],
								email: data[2],
								chi_name: data[3],
							};
						}),
					};

					post('editTeacherSubject', { edit_teacher_subject }).then(({ status }) => {
						if (status) {
							fetch(() => {
								this.setState(
									{
										open_edit_dialog: false,
										teacher_subject_file: [],
										edit_teacher_subject_errors: [],
									},
									() => {
										sorting();
									}
								);
							});
						}
					});
				}
			};
			reader.readAsArrayBuffer(file);
		}
	};

	download = () => {
		let { years, selectedYear, header_format } = this.state;
		let title = find(years, { id: selectedYear }).name + '老師科目資料';
		let data = [header_format];

		map(this.state.teacher_subjects, ({ class_name, subject_name, email, chi_name }) => {
			data.push([class_name, subject_name, email, chi_name]);
		});
		excelTemplate(title, data);
	};

	render() {
		const { history } = this.props;
		const {
			isLoading,
			years,
			classNames,
			showTeacherSubjects,
			selectedYear,
			selectedClass,
			table_columns,
			sort_column,
			sort_order,
			open_edit_dialog,
			edit_teacher_subject_errors,
		} = this.state;
		const { sorting, onChangeYear, onChangeClass, handleEditDialog, onUploadFile, save, download } = this;

		return (
			<Fragment>
				<Loader isLoading={isLoading} />

				<div className="root">
					<Nav history={history} title="老師科目設定" />

					<Container className="root-content">
						<ToolbarBuffer />

						<Box mb={3} display="flex" alignItems="center">
							<Box mr={3}>
								<FormControl variant="outlined">
									<InputLabel id="class">年度</InputLabel>
									<Select labelId="class" value={selectedYear} labelWidth={35} onChange={(event) => onChangeYear(event.target.value)}>
										{years.map((year, index) => (
											<MenuItem key={index} value={year.id}>
												{year.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>

							<Hidden smDown>
								<Box mr={3}>
									<Fab variant="extended" color="primary" size="medium" onClick={() => handleEditDialog(true)}>
										<Icon className="margin-right-10">cloud_upload</Icon>更新老師科目資料
									</Fab>
								</Box>

								<Box>
									<Fab variant="extended" color="primary" size="medium" onClick={() => download(true)}>
										<Icon className="margin-right-10">cloud_download</Icon>下載老師科目範本
									</Fab>
								</Box>
							</Hidden>

							<Hidden mdUp>
								<Box>請以電腦進行更新</Box>
							</Hidden>
						</Box>

						<Box mb={3} display="flex" alignItems="center">
							<Box mr={3}>
								<FormControl variant="outlined">
									<InputLabel id="class">班別</InputLabel>
									<Select labelId="class" value={selectedClass} labelWidth={35} onChange={(event) => onChangeClass(event.target.value)}>
										<MenuItem value="allClass">全部班別</MenuItem>
										{classNames.map((class_name, index) => (
											<MenuItem key={index} value={class_name}>
												{class_name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</Box>

						<Box>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											{table_columns.map((column, index) => (
												<TableCell
													key={index}
													className={column.sort ? 'table-header pointer' : 'table-header'}
													align="center"
													onClick={() => {
														column.sort && sorting(column.property);
													}}
												>
													{column.sort && (
														<TableSortLabel
															className="table-sort-label"
															active={sort_column === column.property}
															direction={sort_order}
															hideSortIcon
														/>
													)}
													{column.name}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{showTeacherSubjects.length > 0 ? (
											showTeacherSubjects.map((teacher, index) => (
												<TableRow hover key={index}>
													<TableCell component="th" scope="row" align="center">
														{teacher.class_name}
													</TableCell>
													<TableCell align="center">{teacher.subject_name}</TableCell>
													<TableCell align="center">{teacher.chi_name}</TableCell>
												</TableRow>
											))
										) : (
											<TableNoData colSpan="3" msg="沒有老師" />
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						{/* edit teacher subject dialog */}
						<Dialog fullScreen open={open_edit_dialog} onClose={() => handleEditDialog(false)}>
							<AppBar className="dialog-app-bar">
								<Toolbar>
									<IconButton color="inherit" onClick={() => handleEditDialog(false)}>
										<Icon>close</Icon>
									</IconButton>
									<Typography variant="h6" className="dialog-title">
										更新老師科目資料
									</Typography>
								</Toolbar>
							</AppBar>
							<Box mt={3} mx={3}>
								<Container>
									<Box mb={3}>
										<FormControl variant="outlined" fullWidth>
											<InputLabel id="class">年度</InputLabel>
											<Select labelId="class" value={selectedYear} labelWidth={35} onChange={(event) => onChangeYear(event.target.value)}>
												{years.map((year, index) => (
													<MenuItem key={index} value={year.id}>
														{year.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Box>

									<Box mb={3} textAlign="center">
										<DropzoneArea
											dropzoneClass="dropzone"
											dropzoneText="請以XLSX格式上老師科目資料"
											useChipsForPreview
											acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
											filesLimit={1}
											onChange={(teacher_subject_file) => onUploadFile(teacher_subject_file)}
										/>
									</Box>

									<Box mb={3} display={edit_teacher_subject_errors.length > 0 ? 'block' : 'none'}>
										<ErrorBox errors={edit_teacher_subject_errors} />
									</Box>

									<Box display="flex" justifyContent="center" alignItems="center">
										<Box mr={3}>
											<Fab variant="extended" size="medium" onClick={() => handleEditDialog(false)}>
												<Icon>close</Icon>取消
											</Fab>
										</Box>
										<Box>
											<Fab variant="extended" color="primary" size="medium" onClick={() => save()}>
												<Icon>save</Icon>儲存
											</Fab>
										</Box>
									</Box>
								</Container>
							</Box>
						</Dialog>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default TeacherSubjectSetting;
