import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Home from '../containers/Home';
import CourseList from '../containers/teacher/courseList/CourseList';
import HomeworkList from '../containers/teacher/homework/HomeworkList';
import AnalysisAdmin from '../containers/teacher/analysis/AnalysisAdmin';
import AnalysisTeacher from '../containers/teacher/analysis/AnalysisTeacher';
import AnalysisTeacherDetail from '../containers/teacher/analysis/AnalysisTeacherDetail';
import Record from '../containers/student/Record';
import YearSetting from '../containers/teacher/setting/YearSetting';
import TeacherSetting from '../containers/teacher/setting/TeacherSetting';
import TeacherSubjectSetting from '../containers/teacher/setting/TeacherSubjectSetting';
import StudentSetting from '../containers/teacher/setting/StudentSetting';
import HomeworkTypeSetting from '../containers/teacher/setting/HomeworkTypeSetting';
import PlatformSetting from '../containers/teacher/setting/PlatformSetting';
import Dashboard from '../containers/student/dashboard/Dashboard';
import Blackboard from '../containers/blackboard/Blackboard';
import SubjectSetting from '../containers/teacher/setting/SubjectSetting';
import { get } from '../helpers/ApiHelper';
import { setChiName, setClassId, setEngName, setIsAdmin, setIsAuth, setRole, setTeacherId } from '../redux/user_slice';
import { useDispatch } from 'react-redux';
import BlackboardLogin from '../containers/blackboard/BlackboardLogin';
import Config from '../const/Config';

const blackboardViews = ['classroom', 'parentView'].map(x => Config.function[x] && x.replace('room', '')).filter(x => x).join("|") || false;

const Routes = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const { data: { status, isAdmin, teacher_id, role, chi_name, eng_name, class_id } = {} } = await get('auth');
      dispatch(setIsAuth(status));
      dispatch(setIsAdmin(isAdmin));
      dispatch(setTeacherId(teacher_id));
      dispatch(setRole(role));
      dispatch(setChiName(chi_name));
      dispatch(setEngName(eng_name));
      dispatch(setClassId(class_id));
      setLoading(false);
    };
    fetch();
  }, [dispatch]);

  if (loading) return <h6>Loading...</h6>;

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <PrivateRoute path="/course-list" role={['teacher']} exact>
          <CourseList />
        </PrivateRoute>
        <PrivateRoute path={['/class-homework/:classId', '/subject-homework/:classId/:subject_id']} role={['teacher']} exact>
          <HomeworkList />
        </PrivateRoute>
        <PrivateRoute path="/analysis-admin" role={['teacher']} exact>
          <AnalysisAdmin />
        </PrivateRoute>
        <PrivateRoute path="/analysis-teacher" role={['teacher']} exact>
          <AnalysisTeacher />
        </PrivateRoute>
        <PrivateRoute path="/analysis-teacher/detail/:studentId" role={['teacher']} exact>
          <AnalysisTeacherDetail />
        </PrivateRoute>
        <PrivateRoute path="/year-setting" role={['teacher']} exact>
          <YearSetting />
        </PrivateRoute>
        <PrivateRoute path="/teacher-setting" role={['teacher']} exact>
          <TeacherSetting />
        </PrivateRoute>
        <PrivateRoute path="/teacher-subject-setting" role={['teacher']} exact>
          <TeacherSubjectSetting />
        </PrivateRoute>
        <PrivateRoute path="/student-setting" role={['teacher']} exact>
          <StudentSetting />
        </PrivateRoute>
        <PrivateRoute path="/subject-setting" role={['teacher']} exact>
          <SubjectSetting />
        </PrivateRoute>
        <PrivateRoute path="/homework-type-setting" role={['teacher']} exact>
          <HomeworkTypeSetting />
        </PrivateRoute>
        <PrivateRoute path="/platform-setting" role={['teacher']} exact>
          <PlatformSetting />
        </PrivateRoute>
        <PrivateRoute path="/student-dashboard" role={['student', 'parentLogin']} exact>
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/student-record/:type" role={['student', 'parentLogin']} exact>
          <Record />
        </PrivateRoute>
        {blackboardViews && (<Route path={`/:type(${blackboardViews})/login`} exact>
          <BlackboardLogin />
        </Route>)}
        {blackboardViews && (<PrivateRoute path={`/:type(${blackboardViews})/blackboard`} role={['class', 'parentView']} exact>
          <Blackboard />
        </PrivateRoute>)}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
