import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isAuth: false,
	isAdmin: false,
	teacherId: null,
	role: null,
	chiName: null,
	engName: null,
	selectedYear: null,
	studentClassId: null,
	classId: null,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setIsAuth: (state, action) => {
			state.isAuth = action.payload;
		},
		setIsAdmin: (state, action) => {
			state.isAdmin = action.payload;
		},
		setTeacherId: (state, action) => {
			state.teacherId = action.payload;
		},
		setRole: (state, action) => {
			state.role = action.payload;
		},
		setChiName: (state, action) => {
			state.chiName = action.payload;
		},
		setEngName: (state, action) => {
			state.engName = action.payload;
		},
		setSelectedYear: (state, action) => {
			state.selectedYear = action.payload;
		},
		setStudentClassId: (state, action) => {
			state.studentClassId = action.payload;
		},
		setClassId: (state, action) => {
			state.classId = action.payload;
		},
	},
});

export const { setIsAuth, setIsAdmin, setTeacherId, setRole, setChiName, setEngName, setSelectedYear, setClassId, setStudentClassId } = userSlice.actions;

export const selectIsAuth = (state) => state.user.isAuth;
export const selectIsAdmin = (state) => state.user.isAdmin;
export const selectTeacherId = (state) => state.user.teacherId;
export const selectRole = (state) => state.user.role;
export const selectChiName = (state) => state.user.chiName;
export const selectEngName = (state) => state.user.engName;
export const selectSelectedYear = (state) => state.user.selectedYear;
export const selectClassId = (state) => state.user.classId;
export const selectStudentClassId = (state) => state.user.studentClassId;

export default userSlice.reducer;
