import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = makeStyles((theme) => ({
	root: {
		textTransform: 'none',
		whiteSpace: 'nowrap',
		fontWeight: (props) => (props.lightFont ? 'normal' : 'bold'),
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
	},
	contained: {
		minWidth: 125,
	},
	outlined: {
		minWidth: 125,
	},
	borderRadius: {
		borderRadius: 40,
	},
	primarydefault: {
		color: theme.palette.primary.main,
	},
	secondarydefault: {
		color: theme.palette.secondary.main,
	},
	successdefault: {
		color: theme.palette.success.main,
	},
	primaryoutlined: {
		border: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.primary.main,
	},
	secondaryoutlined: {
		border: `1px solid ${theme.palette.secondary.main}`,
		color: theme.palette.secondary.main,
	},
	successoutlined: {
		border: `1px solid ${theme.palette.success.main}`,
		color: theme.palette.success.main,
	},
	primarycontained: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	secondarycontained: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
	},
	successcontained: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.success.contrastText,
	},
}));

const CustomButton = ({ children, className, rounded = true, lightFont = false, variant = 'default', color = 'primary', ...props }) => {
	const classes = styles({ lightFont });

	return (
		<Button
			className={[
				classes.root,
				rounded ? classes.borderRadius : '',
				variant === 'contained' ? classes.contained : '',
				variant === 'outlined' ? classes.outlined : '',
				classes[`${color}${variant}`],
				className,
			].join(' ')}
			{...props}
		>
			{children}
		</Button>
	);
};

CustomButton.propTypes = {};
export default CustomButton;
