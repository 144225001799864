import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const ErrorBox = ({ errors }) => (
	<Alert severity="error">
		<AlertTitle>錯誤</AlertTitle>
		{errors.map((error, index) => (
			<div key={index}>- {error}</div>
		))}
	</Alert>
);

export default ErrorBox;
