import React, { Fragment, useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { get } from '../../../helpers/ApiHelper';
import { getDate, now, tomorrow, yesterday } from '../../../helpers/TimeHelper';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Nav from '../../../components/Nav';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import { Icon, Tab, Tabs } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TeacherHomeworkTable from './_partials/TeacherHomeworkTable';
import RemoveHomeworkDialog from './_partials/RemoveHomeworkDialog';
import EditHomeworkDialog from './_partials/EditHomeworkDialog';
import CollectHomeworkDialog from './_partials/CollectHomeworkDialog';
import Button from '../../../components/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Pagination from '@material-ui/lab/Pagination';
import { databaseLimit } from '../../../const/Const';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from '../../../redux/user_slice';
import uniqBy from 'lodash/uniqBy';

const styles = makeStyles((theme) => ({
	root: {},
	title: {
		fontWeight: 600,
	},
}));

const HomeworkList = (props) => {
	const classes = styles();
	const theme = useTheme();
	const matchXsDown = useMediaQuery(theme.breakpoints.down('xs'));

	const isAdmin = useSelector(selectIsAdmin);
	const CollectRef = useRef();
	const RemoveRef = useRef();
	const EditRef = useRef();

	const { classId, subject_id } = useParams();
	const [mode, setMode] = useState('date');
	const [tabIndex, setTabIndex] = useState('create');
	const [selectedDate, setSelectedDate] = useState(now());
	const [selectedFilter, setSelectedFilter] = useState('all');
	const [pageTotal, setPageTotal] = useState(1);
	const [page, setPage] = useState(1);

	const [isLoading, setIsLoading] = useState(true);
	const [classInfo, setClassInfo] = useState([]);
	const [teacherSubject, setTeacherSubject] = useState({});
	const [homeworks, setHomeworks] = useState([]);
	const [outstandings, setOutstandings] = useState([]);
	const [absents, setAbsents] = useState([]);
	const [resubmits, setResubmits] = useState([]);
	const [bufferEditDay, setBufferEditDay] = useState(0)

	const fetchSetting = async () => {
		const settingInfo = await get('getOneSetting/bufferEditDay');
		const settingInfoStatus = settingInfo.status !== false && settingInfo.result !== undefined;
		setBufferEditDay(settingInfoStatus? settingInfo.result[0].integer : 0);
	};

	const fetchInfo = async () => {
		setIsLoading(true);

		const classInfo = await get(`getOneClass/${classId}`);
		if (classInfo.status !== false && classInfo.result !== undefined) {
			setClassInfo(classInfo.result[0]);
		}

		if (!!subject_id) {
			const teacherSubject = await get(`getTeacherSubject/${classId}/${subject_id}`);
			if (teacherSubject.status !== false && teacherSubject.result !== undefined) {
				setTeacherSubject(teacherSubject.result[0]);
			}
		}

		setIsLoading(false);
	};

	const fetchTotal = async () => {
		setIsLoading(true);

		const classIds = [classId];
		const query = isAdmin ? 'getAllHomeworkTotal' : 'getAllHomeworkTotalByTeacher';
		const total = await get(`${query}/${classIds}/${selectedFilter}`);
		const totalStatus = total.status !== false && total.result !== undefined;
		setPageTotal(totalStatus ? Math.ceil(total.result[0].total / databaseLimit) : 0);

		setIsLoading(false);
	};

	const fetchHomework = async () => {
		setIsLoading(true);

		const classIds = [classId];
		const query = isAdmin ? 'getClassHomework' : 'getClassHomeworkByTeacher';
		const homeworks = await get(`${query}/${classIds}/${tabIndex}/${getDate(selectedDate)}/${selectedFilter}`);
		const homeworksStatus = homeworks.status !== false && homeworks.result !== undefined;
		setHomeworks(homeworksStatus ? homeworks.result : []);

		setIsLoading(false);
	};

	const fetchAllHomework = async () => {
		setIsLoading(true);

		const query = isAdmin ? 'getAllHomework' : 'getAllHomeworkByTeacher';
		const homeworks = await get(`${query}/${classId}/${page}/${selectedFilter}`);
		const homeworksStatus = homeworks.status !== false && homeworks.result !== undefined;
		setHomeworks(homeworksStatus ? homeworks.result : []);

		setIsLoading(false);
	};

	const fetchRecord = async () => {
		setIsLoading(true);

		const homeworkIds = homeworks.map(({ id }) => id);

		const outstandings = await get(`getOutstandingByHomework/${homeworkIds}`);
		const outstandingsStatus = outstandings.status !== false && outstandings.result !== undefined;
		setOutstandings(outstandingsStatus ?  uniqBy(outstandings.result, 'id') : []);

		const absents = await get(`getAbsentByHomework/${homeworkIds}`);
		const absentsStatus = outstandings.status !== false && outstandings.result !== undefined;
		setAbsents(absentsStatus ?  uniqBy(absents.result, 'id') : []);

		const resubmits = await get(`getResubmitByHomework/${homeworkIds}`);
		const resubmitStatus = resubmits.status !== false && resubmits.result !== undefined;
		setResubmits(resubmitStatus ? uniqBy(resubmits.result, 'id') : []);

		setIsLoading(false);
	};

	useEffect(() => {
		fetchSetting();
		fetchInfo();
	}, []);

	useEffect(() => {
		if (mode === 'date') fetchHomework();
	}, [mode, tabIndex, selectedDate, selectedFilter]);

	useEffect(() => {
		if (mode === 'all') fetchAllHomework();
	}, [mode, page, selectedFilter]);

	useEffect(() => {
		if (mode === 'all') fetchTotal();
	}, [mode, selectedFilter]);

	useEffect(() => {
		if (homeworks.length > 0) {
			fetchRecord();
		}
	}, [homeworks]);

	const toggleMode = () => {
		setMode(mode === 'date' ? 'all' : 'date');
	};

	const collect = (homeworkId, collected) => {
		const outstandingRecords = outstandings.filter(({ homework_id }) => homework_id === homeworkId).map(({ student_class_id }) => student_class_id);
		const absentRecords = absents.filter(({ homework_id }) => homework_id === homeworkId).map(({ student_class_id }) => student_class_id);
		CollectRef.current.open(homeworkId, outstandingRecords, absentRecords, collected);
	};

	const edit = (homework = {}) => {
		EditRef.current.open(homework);
	};

	const remove = (homeworkId) => {
		RemoveRef.current.open(homeworkId);
	};

	const filters = [
		{ name: '全部', value: 'all' },
		{ name: '仍欠交', value: 'outstanding' },
		{ name: '已全部遞交', value: 'collected' },
		{ name: '未點收', value: 'uncollected' },
	];

	return (
		<Fragment>
			<Loader isLoading={isLoading} />

			<div className="root">
				<Nav title={`${classInfo.year_name} ${classInfo.class_name} 班 ${teacherSubject?.subject_name || ''}`} />

				<Container className="root-content">
					<ToolbarBuffer />
					<Box mb={4} display="flex" alignItems="center">
						<Button variant="contained" color="primary" startIcon={<Icon>add</Icon>} onClick={() => edit()}>
							新增家課
						</Button>
						<Box m={1} />
						<Button variant="contained" color="secondary" startIcon={<Icon>visibility</Icon>} onClick={toggleMode}>
							{mode === 'date' ? '所有以往功課' : '按日期顯示'}
						</Button>
					</Box>

					{mode === 'date' && (
						<Box mb={2} display="flex" alignItems="center">
							<Icon className="pointer" onClick={() => setSelectedDate(yesterday(selectedDate))}>
								arrow_back_ios
							</Icon>
							<Box mx={2}>
								<KeyboardDatePicker
									autoOk
									inputVariant="outlined"
									label="日期"
									format="YYYY-MM-DD"
									value={selectedDate}
									onChange={(selectedDate) => setSelectedDate(selectedDate.format())}
								/>
							</Box>
							<Icon className="pointer" onClick={() => setSelectedDate(tomorrow(selectedDate))}>
								arrow_forward_ios
							</Icon>
						</Box>
					)}

					<Box mb={2}>
						<Grid
							container
							alignItems="center"
							direction={matchXsDown ? 'column' : 'row'}
							justifyContent={matchXsDown ? 'center' : 'space-between'}
						>
							<Grid item>
								{mode === 'date' ? (
									<Tabs value={tabIndex} indicatorColor="primary" textColor="primary" onChange={(event, value) => setTabIndex(value)}>
										<Tab value="create" label="今日派發" />
										<Tab value="submit" label="今日遞交" />
									</Tabs>
								) : (
									<Typography variant="subtitle1" className={classes.title}>
										所有家課：
									</Typography>
								)}
							</Grid>
							<Grid item>
								<Grid container alignItems="center" spacing={1}>
									<Grid item>
										<Typography>篩選：</Typography>
									</Grid>
									{filters.map(({ name, value }) => (
										<Grid key={value} item>
											<Chip
												label={name}
												color={value === selectedFilter ? 'primary' : 'default'}
												clickable
												onClick={() => setSelectedFilter(value)}
											/>
										</Grid>
									))}
								</Grid>
							</Grid>
						</Grid>
					</Box>

					<Box mb={2}>
						<TeacherHomeworkTable
							bufferEditDay={bufferEditDay}
							homeworks={homeworks}
							outstandings={outstandings}
							absents={absents}
							resubmits={resubmits}
							collect={collect}
							edit={edit}
							remove={remove}
							fetchRecord={fetchRecord}
						/>
					</Box>
					{mode === 'all' && homeworks.length > 0 && page > 0 && (
						<Box mb={2} display="flex" justifyContent="center">
							<Pagination
								count={pageTotal}
								page={page}
								color="primary"
								onChange={(event, value) => {
									setPage(value);
								}}
							/>
						</Box>
					)}
				</Container>
			</div>
			<CollectHomeworkDialog ref={CollectRef} fetchHomework={mode === 'date' ? fetchHomework : fetchAllHomework} fetchRecord={fetchRecord} />
			<EditHomeworkDialog ref={EditRef} classInfo={classInfo} fetchHomework={fetchHomework} />
			<RemoveHomeworkDialog ref={RemoveRef} fetchHomework={fetchHomework} />
		</Fragment>
	);
};

export default HomeworkList;
