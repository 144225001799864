import XLSX from 'xlsx';

export const excelTemplate = (title, data) => {
	let workbook = XLSX.utils.book_new();
	workbook.Props = { Title: title };
	workbook.SheetNames.push(title);

	let worksheet = XLSX.utils.aoa_to_sheet(data);
	workbook.Sheets[title] = worksheet;

	let output = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

	let blob = new Blob([s2ab(output)], {
		type: '',
	});

	let link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = title + '.xlsx';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	return;
};

export const s2ab = (s) => {
	let buf = new ArrayBuffer(s.length);
	let view = new Uint8Array(buf);
	for (let i = 0; i !== s.length; ++i) {
		view[i] = s.charCodeAt(i) & 0xff;
	}
	return buf;
};
