export default {
  // // local
  // host: 'http://localhost:8500',
  // clientId: '595247684042-f419g05ekh1essnv30o0h17id8tajsea.apps.googleusercontent.com',
  // schoolName: 'Coding101',
  // superAdmin: 'project.coding101@gmail.com',
  // function: {
  //   teacher: true,
  //   student: true,
  //   classroom: true,
  //   parentView: true,
  //   parentLogin: true,
  // },

  // // demo
  // host: 'https://demo.coding101.hk',
  // clientId: '595247684042-f419g05ekh1essnv30o0h17id8tajsea.apps.googleusercontent.com',
  // schoolName: 'Coding101',
  // superAdmin: 'project.coding101@gmail.com',
  // function: {
  // 	teacher: true,
  // 	student: true,
  // 	classroom: false,
  // 	parentView: false,
  // 	parentLogin: true,
  // },

  // nyss
  host: 'https://hw.nyss.edu.hk',
  clientId: '595247684042-f419g05ekh1essnv30o0h17id8tajsea.apps.googleusercontent.com',
  schoolName: '五育中學',
  superAdmin: 'project.coding101@gmail.com',
  function: {
    teacher: true,
    student: true,
    classroom: false,
    parentView: false,
    parentLogin: true,
  },
};
