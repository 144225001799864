import moment from 'moment';

// get date
export const getDate = (date) => {
	return moment(date).format('YYYY-MM-DD');
};

// get current year
export const getCurrentYearId = (years) => {
	let current_year = years.find(({ start_date, end_date }) => {
		let date = moment();
		return isCurrentYear(date, start_date, end_date);
	});

	return current_year ? current_year.id : years[0].id;
};

// check is date in current year
export const isCurrentYear = (date, start_date, end_date) => {
	return moment(date).isBetween(start_date, end_date);
};

// Moment now
export const now = () => {
	return moment();
};

// Yesterday
export const yesterday = (date) => {
	return moment(date).subtract(1, 'days');
};

// Tomorrow
export const tomorrow = (date) => {
	return moment(date).add(1, 'days');
};

// Time
export const time = () => {
	return moment().format('H:mm:ss');
};
