import React, { Fragment, useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import Button from '../../../components/Button';
import Nav from '../../../components/Nav';
import Loader from '../../../components/Loader';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import { get } from '../../../helpers/ApiHelper';
import { getCurrentYearId } from '../../../helpers/TimeHelper';
import { getDate, now, yesterday, tomorrow } from '../../../helpers/TimeHelper';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedYear, selectStudentClassId, selectClassId, setSelectedYear, setStudentClassId, setClassId } from '../../../redux/user_slice';
import StudentHomeworkTable from './_partials/StudentHomeworkTable';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { OutlinedInput } from '@material-ui/core';
import { databaseLimit } from '../../../const/Const';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles((theme) => ({
	root: {},
	title: {
		fontWeight: 600,
	},
}));

const Dashboard = () => {
	const classes = styles();
	const theme = useTheme();
	const matchXsDown = useMediaQuery(theme.breakpoints.down('xs'));

	const dispatch = useDispatch();
	const selectedYear = useSelector(selectSelectedYear)?.toString() || '';
	const studentClassId = useSelector(selectStudentClassId);
	const classId = useSelector(selectClassId);
	const date = now();

	const [mode, setMode] = useState('date');
	const [pageTotal, setPageTotal] = useState(1);
	const [page, setPage] = useState(1);

	const [isLoading, setIsLoading] = useState(false);
	const [years, setYears] = useState([]);
	const [students, setStudents] = useState([]);
	const [tabIndex, setTabIndex] = useState('create');
	const [selectedDate, setSelectedDate] = useState(now());
	const [homeworks, setHomeworks] = useState([]);
	const [outstandings, setOutstandings] = useState([]);

	const fetchYear = async () => {
		setIsLoading(true);

		const { status, result } = await get('getYear');
		if (status !== false && result !== undefined) {
			setYears(result);
			if (!selectedYear) dispatch(setSelectedYear(getCurrentYearId(result)));
		}

		setIsLoading(false);
	};

	const fetchStudent = async () => {
		setIsLoading(true);

		const { status, result } = await get(`getOneStudentByYear/${selectedYear}`);
		if (status !== false && result.length > 0) {
			setStudents(result);
		} else {
			dispatch(setStudentClassId(null));
			dispatch(setClassId(null));
			setStudents([]);
			setHomeworks([]);
		}

		setIsLoading(false);
	};

	const fetchTotal = async () => {
		setIsLoading(true);

		const classIds = !!classId ? [classId] : students.map(({ class_id }) => class_id);
		const total = await get(`getPersonalAllTotal/${classIds}/${getDate(date)}`);
		const totalStatus = total.status !== false && total.result !== undefined;
		setPageTotal(totalStatus ? Math.ceil(total.result[0].total / databaseLimit) : 0);

		setIsLoading(false);
	};

	const fetchHomework = async () => {
		setIsLoading(true);

		const classIds = !!classId ? [classId] : students.map(({ class_id }) => class_id);
		const homeworks = await get(`getClassHomework/${classIds}/${tabIndex}/${getDate(selectedDate)}`);
		const homeworksStatus = homeworks.status !== false && homeworks.result !== undefined;
		setHomeworks(homeworksStatus ? homeworks.result : []);

		setIsLoading(false);
	};

	const fetchComingHomework = async () => {
		setIsLoading(true);

		const classIds = !!classId ? [classId] : students.map(({ class_id }) => class_id);
		const homeworks = await get(`getPersonalAll/${classIds}/${page}`);
		const homeworksStatus = homeworks.status !== false && homeworks.result !== undefined;
		setHomeworks(homeworksStatus ? homeworks.result : []);

		setIsLoading(false);
	};

	const fetchOutstanding = async () => {
		setIsLoading(true);

		const homeworkIds = homeworks.map(({ id }) => id);
		const outstandings = await get(`getPersonalOutstandingByHomework/${homeworkIds}`);
		const outstandingsStatus = outstandings.status !== false && outstandings.result !== undefined;
		setOutstandings(outstandingsStatus ? outstandings.result : []);

		setIsLoading(false);
	};

	useEffect(() => {
		fetchYear();
	}, []);

	useEffect(() => {
		if (selectedYear) fetchStudent();
	}, [selectedYear]);

	useEffect(() => {
		if (mode === 'date' && selectedDate && tabIndex && students.length > 0) fetchHomework();
	}, [mode, students, classId, tabIndex, selectedDate]);

	useEffect(() => {
		if (mode === 'all' && students.length > 0) fetchComingHomework();
	}, [mode, students, classId, page]);

	useEffect(() => {
		if (mode === 'all' && students.length > 0) fetchTotal();
	}, [mode, students, classId]);

	useEffect(() => {
		if (homeworks.length > 0) fetchOutstanding();
	}, [homeworks]);

	const toggleMode = () => {
		setMode(mode === 'date' ? 'all' : 'date');
	};

	const onChangeYear = (selectedYear) => {
		dispatch(setSelectedYear(selectedYear));
	};

	const onChangeTabIndex = (value) => {
		setTabIndex(value);
	};

	const onChangeDate = (value) => {
		setSelectedDate(value);
	};

	const onChangeClass = (studentClassId) => {
		if (studentClassId) {
			const student = students.find(({ student_class_id }) => student_class_id === studentClassId);
			const { class_id } = student;
			dispatch(setStudentClassId(studentClassId));
			dispatch(setClassId(class_id));
		} else {
			dispatch(setStudentClassId(null));
			dispatch(setClassId(null));
		}
	};

	const getStudentInfo = () => {
		if (studentClassId) {
			const student = students.find(({ student_class_id }) => student_class_id === studentClassId);
			if (!!student) {
				const { class_name, chi_name, eng_name, class_number } = student || {};
				return `${class_name} ${chi_name || eng_name} (${class_number})`;
			}
		} else {
			const student = students[0] || null;
			if (!!student) {
				const { chi_name, eng_name } = student || {};
				return chi_name || eng_name;
			}
		}
		return '未有學生資料';
	};

	return (
		<Fragment>
			<Loader isLoading={isLoading} />

			<div className="root">
				<Nav title="電子手冊" />

				<Container className="root-content">
					<ToolbarBuffer />
					<Box mb={4}>
						<Button variant="contained" color="secondary" startIcon={<Icon>visibility</Icon>} onClick={toggleMode}>
							{mode === 'date' ? '所有將要遞交功課' : '按日期顯示'}
						</Button>
					</Box>
					<Box mb={2} display="flex" alignItems="center" justifyContent={matchXsDown ? 'center' : 'flex-start'}>
						{mode === 'date' && (
							<Icon className="pointer" onClick={() => onChangeDate(yesterday(selectedDate))}>
								arrow_back_ios
							</Icon>
						)}
						<Box mx={mode === 'date' ? 2 : 0} display="flex" flexDirection={matchXsDown ? 'column' : 'row'} alignItems="center">
							<FormControl variant="outlined" fullWidth={matchXsDown}>
								<InputLabel id="year">年度</InputLabel>
								<Select labelId="year" value={selectedYear} labelWidth={35} onChange={(event) => onChangeYear(event.target.value)}>
									{years.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{mode === 'date' && (
								<React.Fragment>
									<Box m={1} />
									<KeyboardDatePicker
										autoOk
										inputVariant="outlined"
										label="日期"
										format="YYYY-MM-DD"
										value={selectedDate}
										onChange={(selectedDate) => onChangeDate(selectedDate.format())}
									/>
								</React.Fragment>
							)}
							<Box m={1} />
							<FormControl variant="outlined" fullWidth={matchXsDown}>
								<InputLabel id="class" shrink>
									班級
								</InputLabel>
								<Select
									labelId="class"
									displayEmpty
									value={studentClassId || ''}
									labelWidth={35}
									input={<OutlinedInput notched label="班級" />}
									onChange={(event) => {
										onChangeClass(event.target.value);
									}}
								>
									<MenuItem value="">全部班別</MenuItem>
									{students.map(({ student_class_id, class_name }) => (
										<MenuItem key={student_class_id} value={student_class_id}>
											{class_name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Box m={1} />
							{getStudentInfo()}
						</Box>
						{mode === 'date' && (
							<Icon className="pointer" onClick={() => onChangeDate(tomorrow(selectedDate))}>
								arrow_forward_ios
							</Icon>
						)}
					</Box>
					{mode === 'date' ? (
						<Box mb={2} display="flex" flexDirection={matchXsDown ? 'column' : 'row'} alignItems="center" justifyContent="space-between">
							<Tabs value={tabIndex} indicatorColor="primary" textColor="primary" onChange={(event, value) => onChangeTabIndex(value)}>
								<Tab value="create" label="今日派發" />
								<Tab value="submit" label="今日遞交" />
							</Tabs>
						</Box>
					) : (
						<Box mb={2}>
							<Typography variant="subtitle1" className={classes.title}>
								所有家課：
							</Typography>
						</Box>
					)}
					<Box mb={2}>
						<StudentHomeworkTable homeworks={homeworks} outstandings={outstandings} />
					</Box>
					{mode === 'all' && homeworks.length > 0 && page > 0 && (
						<Box mb={2} display="flex" justifyContent="center">
							<Pagination
								count={pageTotal}
								page={page}
								color="primary"
								onChange={(event, value) => {
									setPage(value);
								}}
							/>
						</Box>
					)}
				</Container>
			</div>
		</Fragment>
	);
};
export default Dashboard;
