import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AbsentRow from './AbsentRow';
import { getDate } from '../../../../helpers/TimeHelper';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import { selectIsAdmin, selectTeacherId } from '../../../../redux/user_slice';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import OutstandingRow from './OutstandingRow';
import ResubmitRow from './ResubmitRow';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '../../../../components/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AlarmIcon from '@material-ui/icons/Alarm';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import moment from 'moment';

const styles = makeStyles((theme) => ({
	root: {},
	bold: {
		fontWeight: 600,
	},
	summary: {
		fontWeight: 600,
		marginLeft: theme.spacing(1),
	},
	itemTitle: {
		fontWeight: 600,
		marginRight: theme.spacing(1),
	},
	secondary: {
		color: theme.palette.secondary.main,
	},
	success: {
		color: theme.palette.success.main,
	},
}));

const TeacherHomeworkTable = ({
  	bufferEditDay= 0,
	homeworks = [],
	outstandings = [],
  	absents = [],
	resubmits = [],
	collect = () => {},
	edit = () => {},
	remove = () => {},
	fetchRecord = () => {},
}) => {
	const classes = styles();
	const isAdmin = useSelector(selectIsAdmin);
	const teacherId = useSelector(selectTeacherId);
	const currentDate = moment();

	return (
		<React.Fragment>
			{homeworks.length > 0 ? (
				<React.Fragment>
					{homeworks.map((homework, index) => {
						const {
							id,
							teacher_id,
							subject_name,
							homework_type_name,
							description,
							create_date,
							submit_date,
							update_date,
							collected,
							is_count,
							teacher_chi_name,
							teacher_eng_name,
						} = homework;
						console.log(homework)

						const outstandingRecords = outstandings.filter((outstanding) => outstanding.homework_id === homework.id);
						const absentRecords = absents.filter((absent) => absent.homework_id === homework.id);
						const resubmitRecords = resubmits.filter((resubmit) => resubmit.homework_id === homework.id);
						let expired = !!collected;
						if(update_date) {
							const differenceDay = currentDate.diff(update_date, 'days');
							expired = differenceDay > bufferEditDay;
						}

						const editPermission = Boolean(isAdmin || (teacherId === teacher_id && !collected) || !expired);

						let status = collected ? '已全部遞交' : '未進行點收';
						let color = collected ? 'success' : '';
						if (outstandingRecords.length > 0 || absentRecords.length > 0) {
							const text = [];
							if(outstandingRecords.length > 0) text.push(`欠 ${outstandingRecords.length} 人`);
							if(absentRecords.length > 0) text.push(`缺席 ${absentRecords.length} 人`);
							status = text.join('、');
							color = 'secondary';
						}

						const details = [
							{ title: '派發老師', value: teacher_chi_name || teacher_eng_name },
							{ title: '科目', value: subject_name },
							{ title: '種類', value: homework_type_name },
							{ title: '描述', value: description },
							{ title: '派發日期', value: getDate(create_date) },
							{ title: '遞交日期', value: getDate(submit_date) },
							{ title: '計算欠交記錄', value:  !!is_count? '是' : '否'}
						];

						const summaries = [
							{
								key: 'description',
								icon: <DescriptionIcon className={classes.success} />,
								value: `${subject_name}: ${homework_type_name ? `[${homework_type_name}] ` : ''}${description}`,
								sm: 5,
							},
							{
								key: 'teacher',
								icon: <AccountCircleIcon />,
								value: teacher_chi_name || teacher_eng_name,
								sm: 2,
							},
							{
								key: 'submitDate',
								icon: <AlarmIcon />,
								value: getDate(submit_date),
								sm: 2,
							},
							{
								key: 'status',
								icon: <AssignmentIndIcon />,
								value: status,
								sm: 2,
								color,
							},
						];

						return (
							<Accordion key={index}>
								<AccordionSummary className="expand-header expand-padding">
									<Grid container spacing={2}>
										{summaries.map(({ key, icon, value, sm, color = null }) => (
											<Grid key={key} item xs={12} sm={sm}>
												<Box display="flex" alignItems="center">
													{icon}
													<Typography className={[classes.summary, color ? classes[color] : ''].join(' ')}>{value}</Typography>
												</Box>
											</Grid>
										))}
									</Grid>
								</AccordionSummary>
								<AccordionDetails className="expand-padding">
									<Box p={1} flex={1}>
										{details.map(({ title, value = null }, index) => (
											<Box key={index} mb={2} display="flex">
												<Typography variant="body1" className={classes.itemTitle}>
													{title}:
												</Typography>
												<Typography variant="body1">{value || '-'}</Typography>
											</Box>
										))}
										{Boolean(collected) && (
											<Box>
												<Box my={2}>
													<Typography className={[classes.bold, classes[color]].join(' ')}>{status}</Typography>
												</Box>
												{absentRecords.length > 0 && (
													<React.Fragment>
														<Box my={2}>
															<Divider />
														</Box>
														<Typography variant="body1" className={classes.itemTitle}>
															缺席:
														</Typography>
														<AbsentRow homeworkId={id} absents={absentRecords} fetchRecord={fetchRecord} />
													</React.Fragment>
												)}
												{outstandingRecords.length > 0 && (
													<React.Fragment>
														<Box my={2}>
															<Divider />
														</Box>
														<Typography variant="body1" className={classes.itemTitle}>
															欠交:
														</Typography>
														<OutstandingRow homeworkId={id} outstandings={outstandingRecords} fetchRecord={fetchRecord} />
													</React.Fragment>
												)}
												{resubmitRecords.length > 0 && (
													<React.Fragment>
														<Box my={2}>
															<Divider />
														</Box>
														<Typography variant="body1" className={classes.itemTitle}>
															已補交:
														</Typography>
														<ResubmitRow resubmits={resubmitRecords} />
													</React.Fragment>
												)}
											</Box>
										)}
									</Box>
								</AccordionDetails>
								{editPermission && (
									<AccordionActions className="justify-content-center">
										<Box p={1} flex={1}>
											<Box mb={2}>
												<Divider />
											</Box>
											<Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
												<Button color="primary" startIcon={<PlaylistAddCheckIcon />} onClick={() => collect(id, collected)}>
													{collected? '重新點收' : '點收'}
												</Button>
												<Button color="success" startIcon={<EditIcon />} onClick={() => edit(homework)}>
													編輯
												</Button>
												<Button color="secondary" startIcon={<DeleteIcon />} onClick={() => remove(id)}>
													刪除
												</Button>
											</Box>
										</Box>
									</AccordionActions>
								)}
							</Accordion>
						);
					})}
				</React.Fragment>
			) : (
				<Box>
					<Alert severity="info">沒有功課記錄</Alert>
				</Box>
			)}
		</React.Fragment>
	);
};

export default TeacherHomeworkTable;
