import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: 9999,
		color: '#fff',
	},
}));

const Loader = ({ isLoading = false, loading = false }) => {
	const classes = useStyles();
	return (
		<div>
			<Backdrop className={classes.backdrop} open={isLoading || loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
};

export default Loader;
