import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import Nav from '../../../components/Nav';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { get, post } from '../../../helpers/ApiHelper';
import { TextField } from '@material-ui/core';
import ErrorBox from '../../../components/ErrorBox';

const PlatformSetting = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [settings, setSettings] = useState([]);
	const [openDialog, setOpenDialog] = useState(false);

	const [editKey, setEditKey] = useState('');
	const [editText, setEditText] = useState('');
	const [editType, setEditType] = useState('text');
	const [editId, setEditId] = useState(null);
	const [editValue, setEditValue] = useState(null);
	const [errors, setErrors] = useState([]);

	const headers = [
		{ key: 'item', text: '項目'},
		{ key: 'setting', text: '設定'},
		{ key: 'value', text: '行動' },
	];

	const rows = [
		{key: 'bufferEditDay', text: '點收緩衝期（0日起）', type: 'integer', unit: '天'}
	]

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setIsLoading(true);
		const { result } = await get('getSetting');
		setSettings(result);
		setIsLoading(false);
	}

	const handleEditDialog = (key, text, type, id, value) => {
		setEditKey(key);
		setEditText(text);
		setEditType(type);
		setEditId(id);
		setEditValue(value);
		setErrors([]);
		setOpenDialog(true);
	}

	const verify = () => {
		let errors = [];

		if(!editValue) {
			errors.push('必需輸入');
		}

		if(editKey === 'bufferEditDay' && editValue < 0) {
			errors.push('點收緩衝期不能為負數');
		}

		setErrors(errors);
		return errors.length === 0;
	}

	const save = () => {
		if(!verify()) return;

		const edit_setting = {
			id: editId,
			[editType]: editValue
		}

		post('editSetting', { edit_setting }).then(({ status }) => {
			if (status) {
				setEditText('');
				setEditType('text');
				setEditId(null);
				setEditValue(null);
				setErrors([]);
				setOpenDialog(false);
				fetchData();
			}
		});
	}

	return (
		<Fragment>
			<Loader isLoading={isLoading} />

			<div className="root">
				<Nav title="平台設定" />

				<Container className="root-content">
					<ToolbarBuffer />

					<Box my={3}>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										{headers.map(({key, text}) => (
											<TableCell
												key={key}
												className='table-header'
												align="center"
											>
												{text}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{/* eslint-disable-next-line array-callback-return */}
									{rows.map(({key, text, type, unit}) => {
										const setting = settings.find((setting) => setting.name === key);
										if(setting) {
											const id = setting.id;
											let value = setting[type] || '';
											if(type === 'integer' && !value) {
												value = 0
											}

											return (
												<TableRow hover key={key}>
													<TableCell component="th" scope="row" align="center">
														{text}
													</TableCell>
													<TableCell align="center">
														{value} {unit}
													</TableCell>
													<TableCell align="center">
														<Box
															display="flex"
															justifyContent="center"
															alignItems="center"
														>
															<Fab
																size="small"
																color="primary"
																onClick={() =>
																	handleEditDialog(key, text, type, id, value)
																}
															>
																<Icon>edit</Icon>
															</Fab>
														</Box>
													</TableCell>
												</TableRow>
											)
										}
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Container>
			</div>
			<Dialog fullScreen open={openDialog} onClose={() => setOpenDialog(false)}>
				<AppBar className="dialog-app-bar">
					<Toolbar>
						<IconButton color="inherit" onClick={() => setOpenDialog(false)}>
							<Icon>close</Icon>
						</IconButton>
						<Typography variant="h6" className="dialog-title">
							編輯設定
						</Typography>
					</Toolbar>
				</AppBar>
				<Box mt={3} mx={3}>
					<Container>
						<Box mb={3}>
							<TextField
								label={editText}
								fullWidth
								type={editType === 'text'? 'text' : 'number'}
								value={editValue || ''}
								variant="outlined"
								onChange={(event) => setEditValue(event.target.value)}
							/>
						</Box>
						<Box mb={3} display={errors.length > 0 ? 'block' : 'none'}>
							<ErrorBox errors={errors} />
						</Box>
						<Box mb={3} display="flex" justifyContent="center" alignItems="center">
							<Box mr={3}>
								<Fab
									variant="extended"
									size="medium"
									onClick={() => setOpenDialog(false)}
								>
									<Icon>close</Icon>取消
								</Fab>
							</Box>
							<Box>
								<Fab
									variant="extended"
									color="primary"
									size="medium"
									onClick={() => save()}
								>
									<Icon>save</Icon>儲存
								</Fab>
							</Box>
						</Box>
					</Container>
				</Box>
			</Dialog>
		</Fragment>
	)

}

export default PlatformSetting;