import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = makeStyles((theme) => ({
	root: {},
	tableCell: {
		color: 'white',
		border: '1px solid white',
	},
	homeworkContainer: {
		borderRadius: 5,
		backgroundColor: '#d9d9d95c',
	},
}));

const BlackboardHomeworkList = ({ homeworks = [] }) => {
	const classes = styles();

	const defaultSubjects = [
		{ id: 1, name: '中文' },
		{ id: 2, name: '英文' },
		{ id: 3, name: '數學' },
	];

	return (
		<TableContainer>
			<Table>
				<TableBody>
					{defaultSubjects.map((subject, index) => (
						<TableRow key={index}>
							<TableCell component="th" scope="row" align="center" width="20%" className={classes.tableCell}>
								<Typography variant="h4">{subject.name}</Typography>
							</TableCell>
							<TableCell className={classes.tableCell}>
								<Grid container spacing={2}>
									{homeworks
										.filter((homework) => homework.subject_id === subject.id)
										.map(({ id, homework_type_name, description }, index) => (
											<Grid key={index} item>
												<Box p={1} className={classes.homeworkContainer}>
													<Typography variant="h4">
														{`${homework_type_name ? `[${homework_type_name}] ` : ''}${description}`}
													</Typography>
												</Box>
											</Grid>
										))}
								</Grid>
							</TableCell>
						</TableRow>
					))}

					<TableRow>
						<TableCell component="th" scope="row" align="center" className={classes.tableCell}>
							<Typography variant="h4">其他</Typography>
						</TableCell>
						<TableCell className={classes.tableCell}>
							<Box display="flex" flexWrap="wrap">
								{homeworks
									.filter((homework) => [1, 2, 3, 4].indexOf(homework.subject_id) < 0)
									.map((homework, index) => (
										<Box key={index} mb={1} mr={1} p={1} className="homework-box">
											<Typography variant="h4">
												{homework.subject_name}: {homework.homework_type_name ? '[' + homework.homework_type_name + ']' : ''}
												{homework.description}
											</Typography>
										</Box>
									))}
							</Box>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default BlackboardHomeworkList;
