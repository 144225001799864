import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { post } from '../../../../helpers/ApiHelper';
import Config from '../../../../const/Config';
import socketIOClient from 'socket.io-client';
import Loader from '../../../../components/Loader';

const RemoveHomeworkDialog = forwardRef(({ fetchHomework = () => {} }, ref) => {
	const socket = useRef();
	const [open, setOpen] = useState(false);
	const [loading, setIsLoading] = useState(false);
	const [homeworkId, setHomeworkId] = useState(false);

	const onClose = () => setOpen(false);
	useImperativeHandle(ref, () => ({
		open: (homeworkId) => {
			setHomeworkId(homeworkId);
			setOpen(true);
		},
	}));

	useEffect(() => {
		socket.current = socketIOClient(Config.host);
	}, []);

	const remove = () => {
		setIsLoading(true);
		post('deleteHomework', { homework_id: homeworkId }).then(({ status }) => {
			if (status) {
				socket.current.emit('edit');
				fetchHomework();
				onClose();
				setIsLoading(false);
			}
		});
	};

	return (
		<React.Fragment>
			<Loader loading={loading} />
			<Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
				<DialogTitle>確認刪除家課？</DialogTitle>
				<DialogContent>
					<Typography variant="subtitle1">一旦刪除，此家課的相關資料將會一併刪除。</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>取消</Button>
					<Button color="secondary" onClick={remove}>
						刪除
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
});

export default RemoveHomeworkDialog;
