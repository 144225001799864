import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const CourseCard = ({ courseDetails = {}, filteredHomeworks = [], isSubject = false }) => {
	const { class_id, subject_id, class_name, subject_name } = courseDetails;
	const link = isSubject ? `/subject-homework/${class_id}/${subject_id}` : `/class-homework/${class_id}`;

	return (
		<Link to={link} className="link">
			<Paper className="card">
				<Box p={2} display="flex" alignItems="center" className="card-header green-background">
					<Icon>{isSubject ? 'library_books' : 'group'}</Icon>
					<Box mx={1} />
					<Typography variant="h6">{`${class_name}班 ${isSubject ? subject_name : ''}`}</Typography>
					<Box style={{ flexGrow: 1 }} />
					{filteredHomeworks.length > 0 && <Chip color="secondary" label={filteredHomeworks.length} />}
				</Box>
				<Box p={3} className="card-content">
					{/* eslint-disable-next-line array-callback-return */}
					{filteredHomeworks.map(({ subject_name, description }, index) => {
						if (index < 3) {
							return (
								<Box key={index} mb={1}>
									<Grid container alignItems="center" spacing={1}>
										<Grid item xs={1}>
											<Icon className="margin-right-10 grey-text">description</Icon>
										</Grid>
										<Grid item xs={11}>
											<Typography variant="body1" noWrap>
												<b>{`${subject_name}：`}</b>
												{description}
											</Typography>
										</Grid>
									</Grid>
								</Box>
							);
						}
					})}
					{filteredHomeworks.length > 3 && (
						<Box my={1.5} textAlign="center">
							<Icon className="grey-text">more_horiz</Icon>
						</Box>
					)}
					{filteredHomeworks.length === 0 && <Box textAlign="center">今天沒有需要遞交之功課</Box>}
				</Box>
			</Paper>
		</Link>
	);
};

CourseCard.propTypes = {};
export default CourseCard;
