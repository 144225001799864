import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

import { post } from '../helpers/ApiHelper';
import { makeStyles, Container, Typography, Button } from '@material-ui/core';
import Nav from '../components/Nav';
import Loader from '../components/Loader';
import Config from '../const/Config';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAdmin, setIsAuth, setTeacherId, setRole, setChiName, setEngName, selectIsAuth, selectRole } from '../redux/user_slice';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& iframe': {
      display: 'unset !important',
      alignSelf: 'center'
    }
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(0, 0, 2, 0),
    minWidth: '40%',
    borderRadius: '20px',
    '& iframe': {
      minWidth: '40%'
    }
  },
  errorBox: {
    color: 'red',
  },
  googlelogin: {
    paddingBottom: 1
  }
}));

const roles_to_map = new Map([
  ['teacher', '/course-list'],
  ['student', '/student-dashboard'],
  ['parentLogin', '/student-dashboard'],
  ['class', '/class/blackboard'],
  ['parentView', '/parent/blackboard']
])

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const userRole = useSelector(selectRole);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chosenRole, setChosenRole] = useState('');

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (isAuth && !!userRole) {
        if (roles_to_map.has(userRole)) history.push(roles_to_map.get(userRole));
      }
      setLoading(false);
    }, 500);
    return () => { };
  }, [isAuth, userRole]);

  const reduxDispatch = (role, result) => {
    const { teacher_id = null, isAdmin = false, chi_name, eng_name } = result;
    dispatch(setIsAuth(true));
    dispatch(setTeacherId(teacher_id));
    dispatch(setChiName(chi_name));
    dispatch(setEngName(eng_name));
    dispatch(setIsAdmin(isAdmin));
    dispatch(setRole(role));
  };

  const login = useGoogleLogin({
    onSuccess: ({ access_token }) => {
      post('user_login', { token: access_token, chosenRole }).then(({ status, result, error }) => {
        if (status) {
          reduxDispatch(result.role, result);
          if (roles_to_map.has(userRole)) history.push(roles_to_map.get(userRole));
        } else {
          setError(error)
          setLoading(false);
        }
      }).catch(console.log);
    },
    onNonOAuthError: (err) => {
      console.log(err);
      setChosenRole('');
      setError(err.type === "popup_closed" ? null : '請再試一次')
      setLoading(false);
    },
    onError: (err) => {
      console.log(err);
      setError('請再試一次');
      setChosenRole('');
      setLoading(false);
    }
  });

  return (
    <Fragment>
      <Loader loading={loading} />
      <Nav home />
      <main className={classes.root}>
        <Container className={classes.container}>

          <Typography paragraph gutterBottom variant="h5">
            {Config.schoolName}家課管理系統
          </Typography>
          {Config.function.teacher && (
            <Fragment>
              <Button variant="contained" color="primary" size="large" className={classes.button} onClick={
                () => {
                  setChosenRole('teacher');
                  setLoading(true);
                  login();
                }
              }>
                老師 Google 登入
              </Button>
              <br />
            </Fragment>
          )}
          {Config.function.student && (
            <Fragment>
              <Button variant="contained" color="primary" size="large" className={classes.button} onClick={
                () => {
                  setChosenRole('student');
                  setLoading(true);
                  login();
                }
              }>
                學生 Google 登入
              </Button>
              <br />
            </Fragment>
          )}
          {Config.function.classroom && (
            <Fragment>
              <Button variant="contained" color="primary" size="large" className={classes.button} to='/class/login' component={Link}>
                課室登入
              </Button>
              <br />
            </Fragment>
          )}
          {Config.function.parentView && (
            <Fragment>
              <Button variant="contained" color="primary" size="large" className={classes.button} to='/parentView/login' component={Link}>
                家長查閱
              </Button>
              <br />
            </Fragment>
          )}
          {Config.function.parentLogin && (
            <Fragment>
              <Button variant="contained" color="primary" size="large" className={classes.button} onClick={
                () => {
                  setChosenRole('parent');
                  setLoading(true);
                  login();
                }
              }>
                家長 Google 登入
              </Button>
              <br />
            </Fragment>
          )}

          <div className={classes.errorBox}>{error}</div>
        </Container>
      </main>
    </Fragment>
  );
};

export default Home;
